<div class="tabbable" *ngIf="!pageloading;">
  <!-- <button class="scroll-btn left-btn" (click)="scrollTabs('left')">
    <fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>
  </button> -->
  <!-- <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist" #tabContainer> -->

  <!-- <button *ngFor="let eachCategory of categoryTitles; let ind = index"
      [ngClass]="showActiveTab == eachCategory ? 'nav-link active' : 'nav-link ps-4'"
      attr.data-bs-target="#nav-{{eachCategory}}" type="button" role="tab" attr.aria-controls="nav-{{eachCategory}}"
      aria-selected="true"
      (click)="selectedCategory(eachCategory); selectedDropdownCategory = 'Scientific surgery';dropdownOpen=false">
      {{ eachCategory }}
    </button> -->

  <!-- Dropdown for extra categories -->
  <!-- <div class="" (mouseenter)="dropdownOpen = true" (mouseleave)="dropdownOpen = false" >
      <button #buttonEl class="nav-link dropdown-toggle"
        [ngClass]="showActiveTab == selectedDropdownCategory ? 'nav-link active' : 'nav-link ps-4'" type="button"
        id="extraCategoriesDropdown" aria-expanded="false" (click)="positionDropdown()">
        {{ selectedDropdownCategory }}
      </button>
      <ul *ngIf="dropdownOpen" #dropdownMenu class="menu-sub" [class.show]="dropdownOpen"
        aria-labelledby="extraCategoriesDropdown"  >
        <li *ngFor="let extraCategory of extraCategoryTitles" (click)="dropdownOpen = false">
          <a [ngClass]="selectedDropdownCategory == extraCategory ? 'dropdown-item active' : 'dropdown-item'"
            id="nav-{{extraCategory}}-tab" data-bs-toggle="tab" attr.data-bs-target="#nav-{{extraCategory}}" role="tab"
            (click)="selectedCategory(extraCategory); selectedDropdownCategory = extraCategory">
            {{ extraCategory }}
          </a>
        </li>
      </ul>
    </div> -->


  <!-- Filter section -->
  <!-- <div class="position-relative d-inline-block ps-3 pt-3">
      <button type="button" class="btn btn-primary btn-lg filter-btn" (click)="load_filter = !load_filter;"
        aria-label="Toggle Filter">
        <fa-icon [icon]="['fas', 'filter']" class="filter-icon"></fa-icon> Refine by
      </button>
      <span *ngIf="filter_total_count > 0"
        class="badge bg-secondary position-absolute top-0 start-100  filter-count">
        {{ filter_total_count }}
      </span>
    </div> -->

  <!-- <div class="filter" *ngIf="load_filter">
      <div class="card filter-section" #filterCard>
        <div class="card-body" *ngIf="surgicalView || contentTypeView">
          <div class="filter-tag-section" *ngIf="surgicalView && uniqueSurgicalSpecialities.length > 0">
            <h5 class="section-heading">Surgical Speciality</h5>
            <div class="filter-badge">
              <span *ngFor="let speciality of uniqueSurgicalSpecialities" class="filter-tag"
                [class.selected]="isSpecialitySelected(speciality)" (click)="onSpecialityChange(speciality)">
                {{ speciality }}
              </span>
            </div>
          </div>
          <div class="filter-tag-section" *ngIf="contentTypeView && uniqueContentTypes.length > 0">
            <h5 class="section-heading">Content Type</h5>
            <div class="filter-badge">
              <span *ngFor="let type of uniqueContentTypes" class="filter-tag"
                [class.selected]="isContentTypeSelected(type)" (click)="onContentTypeChange(type)">
                {{ type }}
              </span>
            </div>
          </div>
          <button class="clear-filters-btn" (click)="clearFilters()">Clear filters</button>
        </div>
      </div>
    </div> -->

  <!-- </div> -->
  <!-- <button class="scroll-btn right-btn" (click)="scrollTabs('right')">
    <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
  </button> -->
  <div class="tab-content p-2 pt-1 bg-light" [ngClass]="{ 'scrolled': isScrolled }" id="nav-tabContent">
    <div *ngFor="let eachCategory of allCategories; let ind = index">
      <div *ngIf="selectedCatheading === eachCategory" class="tab-pane fade active show" id="nav-{{eachCategory}}"
        role="tabpanel" [attr.aria-labelledby]="'nav-' + eachCategory + '-tab'">

        <app-search #childRef *ngIf="homepageValue" [pageType]="pageType" [category]="selectedCat"
          [allcategory]="AllcategoryArray" (searchEvent)="onSearchEvent($event)" [search_filters]="search_filters"
          (clearEmit)="clearEmit($event)" [uncheck]="uncheck"></app-search>
        <app-search  #searchFilterElement *ngIf="!homepageValue" [pageType]="pageType" [category]="selectedCat"
          [allcategory]="filteredCategoryArray" (searchEvent)="onSearchEvent($event)" [search_filters]="search_filters"
          (clearEmit)="clearEmit($event)" [uncheck]="uncheck"></app-search>
        <form>
          <div class="row mt-1">
            <div class="col filter-div-1 d-flex justify-content-start align-items-center pb-2" #showref>
              <div class="form-check black-label ">
                <input style="cursor:pointer;" type="checkbox" class="form-check-input" id="check1" name="option1"
                (keyup.enter)="checkBoxChange($event,'title')" (change)="checkBoxChange($event,'title')"
                  value="title" [checked]="uncheck['title']">
                <label class="form-check-label" for="check1">Article title</label>
              </div>
              <div class="form-check black-label ">
                <input style="cursor:pointer;" type="checkbox" class="form-check-input" id="check1" name="option1"
                (keyup.enter)="checkBoxChange($event,'author')" (change)="checkBoxChange($event,'author')"
                  value="author" [checked]="uncheck['author']">
                <label class="form-check-label" for="check1">Author</label>
              </div>
              <div class="form-check black-label">
                <input style="cursor:pointer;" type="checkbox" class="form-check-input" id="check2" name="option2"
                (keyup.enter)="checkBoxChange($event,'doi')" (change)="checkBoxChange($event,'doi')" value="doi"
                [checked]="uncheck['doi']">
                <label class="form-check-label" for="check2">DOI</label>
              </div>
            </div>
          </div>
        </form>
        <app-leader-board *ngIf="!hideLeaderboard" [category]="eachCategory" [pageType]="pageType"></app-leader-board>
      </div>
    </div>
  </div>



</div>