import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { MainHomeService } from '../../../services/main-home.service';
import { env } from '../../../config/config';

@Component({
  selector: 'app-portrait',
  templateUrl: './portrait.component.html',
  styleUrl: './portrait.component.scss'
})
export class PortraitComponent {
  @Input() pageloading: boolean;
  advertImagePath = 'adverts/Portrait/'
  // portraitAdvImages: any[] = [
  //   {
  //     path: this.advertImagePath + '300 x 1050 EHS Advanced MIS.png',
  //     url: 'https://europeanherniasociety.eu/news/ehs-advanced-mis-cadaveric-hernia-masterclass-2/',
  //     remove_date: '2024-11-15 23:59:59'
  //   },
  //   {
  //     path: this.advertImagePath + '300 x 1050 EHS Intermediate.png',
  //     url: 'https://europeanherniasociety.eu/events/ehs-intermediate-cadaveric-hernia-masterclass-4/',
  //     remove_date: '2024-11-22 23:59:59'
  //   }
  // ];
  portraitAdvImages: any[] = [];
  currentIndex: number = 0;
  interval: any;
  imagePath: string;
  constructor(@Inject(PLATFORM_ID) private platformId: any, private mainService: MainHomeService) {
    if (isPlatformBrowser(this.platformId)) {
      let data = {
        type: 'Portrait'
      }
      this.mainService.getBillBoardImages(data).subscribe((response: any) => {
        this.portraitAdvImages = response.data;
        // this.filteredImages =this.leaderBoardAdvImages.filter((image: string, index: number) => this.shouldShowImage(image, index));
        this.imagePath = env.advertisementImage;
        if (this.portraitAdvImages.length > 0) this.pageloading = false;
        else if (this.portraitAdvImages.length == 0) {
          this.pageloading = false
          //  this.noAds = true
        };
        this.currentIndex = this.portraitAdvImages.findIndex(image => this.shouldShowImage(image));
        this.interval = setInterval(() => {
          this.currentIndex = (this.currentIndex + 1) % this.portraitAdvImages.length;
          this.portraitAdvImages = this.portraitAdvImages.filter(image => this.shouldShowImage(image));
        }, 7000);

      });
    }

    //   this.currentIndex = this.portraitAdvImages.findIndex(image => this.shouldShowImage(image));
    //   this.interval = setInterval(() => {
    //     this.currentIndex = (this.currentIndex + 1) % this.portraitAdvImages.length;
    //     this.portraitAdvImages = this.portraitAdvImages.filter(image => this.shouldShowImage(image));
    //   }, 7000);
    // }
  }
  // shouldShowImage(image: any, index = 0): boolean {
  //   if (image.end_date == null) return true;
  //   if (!image.end_date) return false;
  //   const removeDate = new Date(image.end_date);
  //   const now = new Date();
  //   return now <= removeDate;
  // }
  shouldShowImage(image: any, index = 0): boolean {
    const now = new Date();

    // Check if start_date exists and is valid
    if (image.start_date) {
      const startDate = new Date(image.start_date);
      if (now < startDate) {
        return false; // Current date is before start_date
      }
    }

    // Check if end_date exists and is valid
    if (image.end_date) {
      const endDate = new Date(image.end_date);
      if (now > endDate) {
        return false; // Current date is after end_date
      }
    }

    // Show image if current date is between start_date and end_date
    return true;
  }
}
