import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareButton } from 'ngx-sharebuttons/button';
import { AuthorsComponent } from '../authors/authors.component';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ArticleService } from '../../services/article.service';
import { Subscription } from 'rxjs';
import { env } from '../../config/config';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, Meta, Title, SafeUrl } from '@angular/platform-browser';
import { NgClass, NgFor, NgIf, NgStyle, isPlatformBrowser } from '@angular/common';
import { articleView, authors_data, download, imagePaths } from '../../interfaces/article';
import { GeneralModule } from '../../general/general.module';
import { ModalFigComponent } from '../modal-fig/modal-fig.component';


@Component({
    standalone: true,
    selector: 'app-article-view-page',
    imports: [ShareButton, FontAwesomeModule, AuthorsComponent, NgIf, NgFor, GeneralModule, NgStyle, NgClass, ModalFigComponent],
    templateUrl: './article-view-page.component.html',
    styleUrl: './article-view-page.component.scss'
})
export class ArticleViewPageComponent implements OnInit {
    @ViewChild('contentLoaded') contentLoaded!: ElementRef<HTMLDivElement>;
    sharedUrl = '';
    embededLink = '';
    journal_id = '';
    subscriptions: Subscription[] = [];
    articleView: articleView;
    articleCoverImageUrl = '';
    surgical_content: any[] = [];
    category = '';
    article_link = '';
    parser!: DOMParser;
    html_new_content = '';
    authorImages: string[] = [];
    html_new_content_dupe: any;
    relatedArticle: any[] = [];
    download_url: download[] = [];
    related_url: download[] = [];
    download_pdf = '';
    activeViewFont = "normal";
    // downloadText = '';
    categorydocheadloading = true;
    pageLoading = true;
    articleTitleLoading = true;
    coverImgLoading = true;
    authorViewLoading = true;
    articleDoiLoading = true;
    articleContetLoading = true;
    relatedArticleLoading = true;
    contentLoadingArray = new Array(8).fill(0);
    articleFilePath: string;
    heading: string;
    caption: string;
    body: string;
    isModalOpen = false;
    div_data: Element;
    activeSection: string = '';
    activeTab = 'tab1';
    imagePaths: imagePaths[] = [];
    isFixed = false;
    containerWidth: number = 0;
    articleAbstract: any;
    isArticleLiked = false;
    formattedLikeCount = '';
    showLikeCount = false;
    articleAbstract_new: any;
    safeUrl: SafeUrl;
    preview_mode: any;
    iframeLinks:any[]=[];
    pdf_url: string;
    constructor(
        private sharedService: SharedService,
        private articleService: ArticleService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private meta: Meta,
        private titleService: Title,

        // private modalservice: NgbModal
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.parser = new DOMParser();
        }
        this.articleView = {
            article_summary: '',
            art_file_path: '',
            article_cover_image: '',
            cover_alt_text: '',
            article_id: '',
            article_title: '',
            article_link: '',
            article_type: '',
            category_dochead: '',
            category_tags: [],
            content_type: [],
            surgical_speciality: [],
            category_heading: {
                id: '',
                title: ''
            },
            article_timeline: [
                {
                    year: '',
                    date: '',
                    month: '',
                    type: '',
                },
            ],
            authors: [
                {
                    affiliation: '',
                    caption: '',
                    corresp_author: '',
                    email: '',
                    fig: '',
                    name: '',
                    orcid: '',
                    twitter_username: ''
                },
            ],
            doi: '',
            e_issn: '',
            html_content: '',
            id: {
                $oid: '',
            },
            journal_display_name: '',
            journal_id: '',
            journal_title: '',
            published_date: '',
            publisher_name: '',
            year: '',
            view_count: 0,
            download_count: 0,
            supplementary_files: [],
            read_paper: '',
            abstract: '',
            like_count: 0,
            author_notes: ''
        };
    }

    ngOnInit() {  

        const routesub = this.route.params.subscribe(() => {
            this.articleLoading();
        });
        this.subscriptions.push(routesub);

    }

    /**
     * function to get article view data
     */
    articleLoading() {
        this.journal_id = env.journalName;
        if (this.route.snapshot.params['category']) {
            this.category = this.route.snapshot.params['category'];
            
        }
        else {
            this.category = this.route.snapshot.params['subcategory'];

        }
        this.preview_mode = this.route.snapshot.params['preview_mode'] ? this.route.snapshot.params['preview_mode'] : '';

        this.article_link = this.route.snapshot.params['article-link'];
        const queryParams = {
            journal_id: this.journal_id,
            category: this.category,
            article_link: this.article_link,
            preview_mode: this.preview_mode
        };
        const viewSub = this.articleService.getArticleViewData(queryParams).subscribe(result => {
            if (result.status === 204) {
                // Redirect to the page not found route
                this.router.navigate(['/page-not-found']);
            }
            else {
                this.articleView = result.data;
                
                this.setMetaTags(this.articleView);
                ///og title
                // this.meta.updateTag({ property: 'og:title', content: this.articleView.article_title });
                // this.meta.updateTag({ property: 'og:image', content: 'https://example.com/path/to/image.jpg' });
                // this.meta.updateTag({ property: 'og:description', content: metaTagData.description });
                // this.meta.updateTag({ property: 'og:url', content: metaTagData.url });


            }

            if (this.articleView?.read_paper) {
                this.safeUrl = this.sanitizeUrl(this.articleView.read_paper);
            }

            this.authorViewLoading = false;
            // this.articleFilePath = this.articleView.art_file_path;
            this.articleFilePath = this.articleView?.art_file_path;
            this.pdf_url = env.articleImage + this.articleFilePath + '/';
            this.showLikeCount = this.articleView?.like_count > 0 ? true : false;
            this.formattedLikeCount = this.formatLikeCount(this.articleView?.like_count);
            this.storeAuthorImage();
            this.joinSurgicalAndContent();
            this.modifyingHtmlContent(this.articleView?.html_content);

            if (this.articleView?.article_id) {
                const relatedParams = {
                    journal_id: this.journal_id,
                    category: this.category,
                    article_id: this.articleView?.article_id
                };
                const relatedSub = this.articleService.getRelatedArticleList(relatedParams).subscribe(result => {
                    this.relatedArticle = result.data;
                    this.relatedArticleLoading = false;

                })
                this.subscriptions.push(relatedSub);
            }
            this.categorydocheadloading = false;
            this.pageLoading = false;
            this.articleTitleLoading = false;
            this.articleDoiLoading = false;
            this.cdr.detectChanges();
            this.checkIfArticleIsLiked(this.articleView?.article_id);

        });
        this.subscriptions.push(viewSub);

        const text = 'bjs article preview';
        const encodedText = ''; 
        if (isPlatformBrowser(this.platformId)) {
            const encodedText = window.btoa(text); 
        }

        this.sharedUrl = env.siteUrl + '/bjs-academy/' + this.category + '/' + this.article_link;

        this.embededLink = env.siteUrl + '/bjs-academy/' + this.category + '/' + this.article_link + '/embed-link/embed#?secret=' + encodedText;

    }

    setMetaTags(data: any) {
        if (data) {
            this.articleCoverImageUrl = env.articleCoverImage + this.journal_id + '/';
            if (data.article_cover_image) {
                this.articleCoverImageUrl += this.articleView?.category_heading?.id + '/' + this.articleView?.article_id + '/' + data.article_cover_image;
            } else {
                this.articleCoverImageUrl = 'bjs_article_cover_image.png';
            }

            const articleUrl = env.siteUrl + '/' + 'bjs-academy' + '/' + this.articleView?.category_heading?.id + '/' + this.articleView?.article_link;
            // const utf8Decoder = new TextDecoder('utf-8');
			// const decodedText = utf8Decoder.decode(new Uint8Array(data.article_title.split('').map((char: string) => char.charCodeAt(0))));
            // console.log(decodedText);
            
            
            const metaTagData = {
                title: data.article_title,
                description: data.article_summary,
                image: this.articleCoverImageUrl
            };

            // Sanitize and create author tags
            const sanitizeText = (text: any) => {
                const sanitizedText = this.sanitizer.sanitize(1, String(text || '').trim());
                return sanitizedText || ''; // Ensure it returns an empty string if sanitization fails
            };

            const authorTags = data.authors.map((author: { name: string; affiliation: string; }) => [
                { name: 'citation_author', content: sanitizeText(author.name) },
                { name: 'citation_author_affiliation', content: sanitizeText(author.affiliation) }
            ]).flat();

            // Combined author tag for all author names, joined by commas
            // const allAuthors = data.authors.map((author: { name: string; }) => sanitizeText(author.name)).join(', ');
            // const combinedAuthorTag = { name: 'citation_authors_combined', content: allAuthors };

            const keywords = [
                ...(data.content_type || []),
                ...(data.surgical_speciality || []),
                ...(data.category_tags || [])
            ].join(', ');
            const abstract = (data.abstract || '')
                .replace(/<[^>]*>/g, '') // Remove HTML tags
                .split(/[.!?]/)
                .filter((sentence: string) => sentence.trim() !== '')
                .slice(0, 3)
                .join('\n');


            this.meta.addTags([
                { name: 'citation_title', content: data.article_title },
                { name: 'citation_abstract', content: abstract },
                { name: 'citation_journal_title', content: data.journal_title },
                { name: 'citation_doi', content: data.doi },
                { name: 'article_type', content: data.article_type },
                { name: 'citation_publisher', content: data.publisher_name },
                { name: 'article_subject_heading', content: data.category_heading.title },
                { name: 'citation_keywords', content: keywords },
                { name: 'citation_publication_date', content: data.published_date },
                { name: 'citation_article_url', content: articleUrl },
                { name: 'citation_issn', content: data.e_issn },
                ...authorTags
                // Add the combined author tag
            ]);

            const firstThreeLines = (data.article_summary || '')
                .replace(/<[^>]*>/g, '') // Remove HTML tags
                .split(/[.!?]/)
                .filter((sentence: string) => sentence.trim() !== '')
                .slice(0, 3)
                .join('\n');

            this.titleService.setTitle(this.toRemoveHtml(metaTagData.title));
            
            this.meta.updateTag({ property: 'og:title', content: this.toRemoveHtml(metaTagData.title) });
            this.meta.updateTag({ property: 'og:description', content: this.toRemoveHtml(metaTagData.description) });
            this.meta.updateTag({ property: 'og:image', content: metaTagData.image });
            this.meta.updateTag({ property: 'twitter:title', content: metaTagData.title });
            this.meta.updateTag({ property: 'twitter:description', content: firstThreeLines });
        }
    }
    toRemoveHtml(description: string): string {
        let decodedText = '';
        if (description) {
            decodedText = description.replace(/<[^>]*>/g, '').trim(); // Removes all HTML tags and trims whitespace
        }
        return decodedText;
    }

    public destroyMetaTagsforSEONew() {
        const metaname = ["citation_title",
            "citation_abstract",
            "citation_journal_title",
            "citation_doi",
            "article_type",
            "citation_publisher",
            "article_subject_heading",
            "citation_keywords",
            "citation_author",
            "citation_author_affliation",
            "citation_publication_date",
            "citation_article_url",
            "citation_issn"];
        const els: HTMLMetaElement[] = this.meta.getTags('name');
        els.forEach(el => {
            if (metaname.includes(el.name)) {
                this.meta.removeTag('name="' + el.name + '"');
            }
        });
        this.titleService.setTitle('BJS Academy');
        this.meta.updateTag(
            { property: 'og:url', content: env.siteUrl },
        );
        this.meta.updateTag(
            { property: 'og:title', content: "BJS Academy" },
        );
        this.meta.updateTag(
            { property: 'og:description', content: "BJS Academy publish the foremost journal for surgeons as well as offering surgeon education resources & courses." },
        );
        this.meta.updateTag(
            { property: 'og:image', content: env.siteUrl + '/bjs_logo.png' },
        );


    }

    setCoverImage(cover_image: string) {
        this.articleCoverImageUrl = env.articleCoverImage + this.journal_id + '/';
        if (cover_image) {
            this.articleCoverImageUrl =
                this.articleCoverImageUrl + this.articleView?.category_heading?.id + '/' + this.articleView?.article_id + '/' + cover_image;
        }
        else {
            this.articleCoverImageUrl = 'bjs_article_cover_image.png';
        }
        this.coverImgLoading = false;
        return this.articleCoverImageUrl;
    }
    storeAuthorImage() {
        this.articleView?.authors?.forEach((eachAuthor: authors_data) => {
            if (eachAuthor.fig) {
                this.authorImages.push(eachAuthor.fig)
            }
        });

    }
    // joinSurgicalAndContent() {
    //     this.surgical_content = [
    //         this.articleView?.category_tags,
    //         this.articleView?.content_type,
    //         this.articleView?.surgical_speciality
    //     ]
    //         .filter((arr): arr is string[] => Array.isArray(arr) && arr.length > 0) // Filter out undefined or empty arrays
    //         .flat(); // Flatten the array of arrays into a single array
    // }
    joinSurgicalAndContent() {
        this.surgical_content = [
            { type: 'category', values: this.articleView?.category_tags },
            { type: 'contentType', values: this.articleView?.content_type },
            { type: 'speciality', values: this.articleView?.surgical_speciality }
        ]
            .filter(item => Array.isArray(item.values) && item.values.length > 0) // Filter out undefined or empty arrays
            .map(item => item.values.map(value => ({ type: item.type, value }))) // Map each value with its type
            .flat(); // Flatten the array of arrays into a single array of objects
    }
    modifyingHtmlContent(html_content: string) {

        this.restructureAbstarct();
        this.imagePaths = [];
        this.download_url = [];
        this.related_url = [];

        if (html_content) {
            const html = this.parser?.parseFromString(html_content, 'text/html');            

            //recreating html............

            // to hide front tag
            const content = html?.querySelector("[class='front']");

            //getting related section pdf or links
            const related = content?.querySelectorAll("[class='related-article']");

            // const link = html?.querySelectorAll('a.ext-link');
            related?.forEach(eachLink => {
                const download_url = eachLink?.getAttribute('href');
                let textContent = eachLink?.getAttribute('attr-related-article-type');
                if (textContent == 'original') {
                    textContent = 'Read paper';
                }
                if (textContent == 'link-button') {
                    textContent = eachLink?.getAttribute('xlink:title');
                }
                let download_array = [];
                if (download_url.includes('www.bjsacademy.com') && download_url.endsWith('.pdf')) {
                    download_array = download_url.split('/');
                    if (download_array.length > 0) {
                        const length = download_array?.length;
                        this.download_pdf = download_array[length - 1];
                        const downloadArray: download = {
                            downloadText: textContent,
                            downloadUrl: env.articleImage + this.articleFilePath + '/' + this.download_pdf
                        }

                        this.related_url.push(downloadArray);
                        // this.download_url = env.articleImage + this.articleFilePath + '/' + this.download_pdf;
                    }
                    // eachLink.setAttribute('style', 'display: none;');
                }
                else {
                    const downloadArray: download = {
                        downloadText: textContent,
                        downloadUrl: download_url
                    }
                    this.related_url.push(downloadArray);
                }
            })

            content?.classList.add('hide');

            //=========== to hide doi section ====================//
            //getting all p tag
            const p_tags = html?.querySelectorAll("[class='p']");
            const regex = /https?:\/\/doi\.org\/(\d+\.\d+)/;
            if (this.articleView?.doi) {
                const match = this.articleView?.doi.match(regex);
                // let extractedDOI='';
                if (match) {
                    var extractedDOI = `doi.org/${match[1]}`;
                }
            }

            //hiding doi tag
            p_tags?.forEach(each_tag => {
                if (each_tag.innerHTML.includes(extractedDOI)) {
                    each_tag.classList.add("hide"); // add the 'hidden' class
                }

                // Find the span element within each_tag
                // const spanElement = each_tag.querySelector('span.bold') as HTMLElement;
                // if (spanElement) {
                //     spanElement.classList.add('section-title');
                //     // Perform additional actions if needed
                // }

                // if(each_tag.attr)
                if (each_tag.attributes[1]?.nodeValue == 'button-read-more' && each_tag.hasChildNodes()) {
                    each_tag.childNodes.forEach((node: any) => {
                        // if (node.textContent == 'Download PDF') {
                        // this.downloadText = node?.textContent;
                        const download_url = node?.href;
                        let download_array = [];
                        if (download_url.includes('www.bjsacademy.com') && download_url.endsWith('.pdf')) {
                            download_array = download_url.split('/');
                            if (download_array.length > 0) {
                                const length = download_array?.length;
                                this.download_pdf = download_array[length - 1];
                                const downloadArray: download = {
                                    downloadText: node?.textContent,
                                    downloadUrl: env.articleImage + this.articleFilePath + '/' + this.download_pdf
                                }
                                this.download_url.push(downloadArray);
                                // this.download_url = env.articleImage + this.articleFilePath + '/' + this.download_pdf;
                            }
                        }
                        else {
                            const downloadArray: download = {
                                downloadText: node?.textContent,
                                downloadUrl: download_url
                            }
                            this.download_url.push(downloadArray);
                        }
                        each_tag.setAttribute('style', 'display: none;');
                        // }
                    })
                    each_tag.classList.add('read-more');
                }

            });



            //getting fig group
            const fig_group_tags = html?.querySelectorAll("[class='fig']");
            fig_group_tags?.forEach(fig_each_tag => {
                const fig_img_tag = fig_each_tag.querySelectorAll('img.graphic');
                fig_img_tag.forEach(img => {
                    const imgSrc = img.attributes[1]?.nodeValue; // Assuming 'src' is the attribute to match
                    if (imgSrc && this.authorImages.includes(imgSrc)) {
                        fig_each_tag.classList.add("hide");
                    }
                });
            })

            //getting graphic tag
            const graphic_tags = html?.querySelectorAll("[class='inline-graphic']");
            graphic_tags?.forEach(graphic_each_tag => {
                if (graphic_each_tag) {
                    // const caption = graphic_each_tag.querySelector("div.caption") as HTMLElement;
                    // caption?.classList.remove('caption');
                    // caption?.classList.add('fig-caption');
                    // const fig_span = graphic_each_tag.querySelector("span.bold") as HTMLElement;
                    // fig_span?.classList.remove('section-title');
                    // fig_span?.classList.add('figure');
                    // if (caption == null) {
                    //     graphic_each_tag.classList.remove('fig');
                    //     graphic_each_tag.classList.add('small-image');
                    // }
                    // else {
                    graphic_each_tag.setAttribute('width', '100%');
                    graphic_each_tag.setAttribute('height', '100%');
                    graphic_each_tag.classList.add('small-image');
                    // }
                    const srcValue = graphic_each_tag?.getAttribute('href') as string;
                    graphic_each_tag.setAttribute(
                        'src',
                        env.apiUrl + 'storage/' + this.articleView.art_file_path +
                        '/' +
                        srcValue
                    );
                    const data: imagePaths = {
                        srcValue: srcValue,
                        imageUrl:
                            env.apiUrl + 'storage/' + this.articleView.art_file_path +
                            '/' +
                            srcValue
                    };
                    this.imagePaths.push(data);

                }
            })

            //restructuring images
            this.imagePaths = [];
            if (html) {
                const fig_tags = Array.from(html?.querySelectorAll("div.fig"))
                    .filter(fig => !fig?.classList?.contains('hide'));
                Array.from(fig_tags).forEach((eachImage) => {
                    const img = eachImage?.querySelector('img') as HTMLElement;
                    if (img) {
                        const caption = eachImage.querySelector("div.caption") as HTMLElement;
                        caption?.classList.remove('caption');
                        caption?.classList.add('fig-caption');
                        const fig_span = eachImage.querySelector("span.bold") as HTMLElement;
                        fig_span?.classList.remove('section-title');
                        fig_span?.classList.add('figure');
                        if (caption == null) {
                            // eachImage.classList.remove('fig');
                            // img.classList.add('small-image');
                        }
                        // else {
                        //     img.setAttribute('width', '100%');
                        //     img.setAttribute('height', '100%');
                        // }
                        img.setAttribute('width', '100%');
                        img.setAttribute('height', '100%');

                        const srcValue = img?.getAttribute('href') as string;
                        img.setAttribute(
                            'src',
                            env.apiUrl + 'storage/' + this.articleView.art_file_path +
                            '/' +
                            srcValue
                        );
                        const data: imagePaths = {
                            srcValue: srcValue,
                            imageUrl:
                                env.apiUrl + 'storage/' + this.articleView.art_file_path +
                                '/' +
                                srcValue
                        };

                        this.imagePaths.push(data);

                    }

                    //for loading vimeo widgets bjs-academyUrl
                    const bjs_url = eachImage.querySelectorAll('[attr-ext-link-type="bjs-academyUrl"]');
                    if (bjs_url.length > 0) {
                        bjs_url.forEach(eachUrl => {
                            const vimeoLink = eachUrl?.getAttribute('href');
                            this.iframeLinks.push(vimeoLink);
                            if (vimeoLink) {
                                const vimeo_link_array = vimeoLink.split('/');
                                const vimeo_id = vimeo_link_array['4'];

                                if (vimeo_id != undefined) {
                                    const embeded_vimeo_html = '<iframe class="bjsclass" scrolling="no" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                    const divele = document.createElement('div');
                                    divele?.classList.add('vimeo_widget');
                                    divele.innerHTML = embeded_vimeo_html;
                                    // eachVimeo.innerHTML = embeded_vimeo_html;
                                    eachUrl.appendChild(divele);
                                }
                                eachImage.classList.remove('fig');
                                eachImage.classList.add('link');
                            }

                        })
                    }

                    //for loading vimeo widgets Url
                    const url = eachImage.querySelectorAll('[attr-ext-link-type="Url"]');
                    if (url.length > 0) {
                        url.forEach(eachUrl => {
                            const vimeoLink = eachUrl?.getAttribute('href');
                            this.iframeLinks.push(vimeoLink);
                            if (vimeoLink) {
                                const vimeo_link_array = vimeoLink.split('/');
                                const vimeo_id = vimeo_link_array['4'];

                                if (vimeo_id != undefined) {
                                    const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                    const divele = document.createElement('div');
                                    divele?.classList.add('vimeo_widget');
                                    divele.innerHTML = embeded_vimeo_html;
                                    // eachVimeo.innerHTML = embeded_vimeo_html;
                                    eachUrl.appendChild(divele);
                                }
                                eachImage.classList.remove('fig');
                                eachImage.classList.add('link');
                            }

                        })
                    }

                    //for loading vimeo widgets
                    const vimeo = eachImage.querySelectorAll('[attr-ext-link-type="vimeoUrl"]');
                    if (vimeo.length > 0) {
                        vimeo.forEach(eachVimeo => {
                            const vimeoLink = eachVimeo?.getAttribute('href');
                            this.iframeLinks.push(vimeoLink);
                            if (vimeoLink) {
                                const vimeo_link_array = vimeoLink.split('/');
                                const vimeo_id = vimeo_link_array['4'];

                                if (vimeo_id != undefined) {
                                    const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                    const divele = document.createElement('div');
                                    divele?.classList.add('vimeo_widget');
                                    divele.innerHTML = embeded_vimeo_html;
                                    // eachVimeo.innerHTML = embeded_vimeo_html;
                                    eachVimeo.appendChild(divele);
                                }
                                eachImage.classList.remove('fig');
                                eachImage.classList.add('link');
                            }
                        });
                    }

                    //for spotify
                    const spotify = eachImage.querySelectorAll('[attr-ext-link-type="anchor-fm-incUrl"]');
                    if (spotify.length > 0) {
                        spotify.forEach(eachSpotify => {
                            const spotifyLink = eachSpotify?.getAttribute('href');
                            this.iframeLinks.push(spotifyLink);
                            if (spotifyLink) {
                                const spotify_link_array = spotifyLink.split('/');
                                const spotify_id = spotify_link_array['4'];
                                if (spotify_id !== undefined) {
                                    const embeded_spotify_html = `
                                    <iframe 
                                        class="wp-embedded-content spotifyclass" 
                                        security="restricted" 
                                        title="Spotify Player" 
                                        src="${spotifyLink}" 
                                        data-secret="2SKUdoXe0b" 
                                        height="205px" 
                                        width="400px" 
                                        frameborder="0" 
                                        scrolling="no" 
                                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                                        allowtransparency="true"
                                        style="border:none; overflow:hidden;">
                                    </iframe>`;

                                    const divele = document.createElement('div');
                                    divele.classList.add('spotify_widget');
                                    divele.innerHTML = embeded_spotify_html;
                                    eachSpotify.appendChild(divele);
                                }

                                eachImage.classList.remove('fig');
                                eachImage.classList.add('link');
                            }

                        })
                    }

                    //for loading twitter links
                    const twitterLinks = eachImage.querySelectorAll('[attr-ext-link-type="twitterUrl"]');

                    if (twitterLinks.length > 0) {
                        twitterLinks.forEach(eachTwitter => {
                            let twitterLink = eachTwitter?.getAttribute('href');
                            this.iframeLinks.push(twitterLink);
                            if (twitterLink && twitterLink.includes('/status/')) {
                                const twitterId = twitterLink.split('/').pop(); // Get the last segment of the URL
                                if (twitterId) {
                                    // Create a blockquote for embedding the tweet
                                    const embededTwitterHtml = `
                                    <blockquote class="twitter-tweet">
                                        <a style="" href="${twitterLink}">Loading...</a>
                                    </blockquote>
                                `;

                                    const tweetContainer = document.createElement('div');
                                    tweetContainer.innerHTML = embededTwitterHtml;
                                    eachImage.appendChild(tweetContainer);

                                    // Load Twitter's embed script
                                    const existingScript = document.querySelector('script#twitter-wjs');
                                    if (!existingScript) {
                                        const script = document.createElement('script');
                                        script.id = 'twitter-wjs';
                                        script.src = "https://platform.twitter.com/widgets.js";
                                        script.onload = () => {
                                            if ((window as any).twttr && (window as any).twttr.widgets) {
                                                (window as any).twttr.widgets.load();
                                            }
                                        };
                                        document.body.appendChild(script);
                                    } else {
                                        // If the script already exists, reload widgets
                                        setTimeout(() => {
                                            if ((window as any).twttr && (window as any).twttr.widgets) {
                                                (window as any).twttr.widgets.load();
                                            }
                                        }, 500);
                                    }

                                    // Hide any elements with the class "disp-quote"
                                    const dispQuotes = eachImage.querySelectorAll('.disp-quote');
                                    dispQuotes.forEach(eachQuote => {
                                        eachQuote.classList.add('hide');
                                    });
                                }
                            }
                        });
                    }

                    //for loading youtube videos
                    const youtubeLinks = eachImage.querySelectorAll('[attr-ext-link-type="youtubeUrl"]');
                    youtubeLinks.forEach(eachYouTube => {
                        const vimeoLink = eachYouTube?.getAttribute('href');
                        this.iframeLinks.push(vimeoLink);
                        if (vimeoLink) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachYouTube.appendChild(divele);
                            }
                            eachImage.classList.remove('fig');
                            eachImage.classList.add('link');
                        }
                    });

                });

                //for loading iframes which urls outside the fig tag

                //for loading twitter links
                const twitterLinks = html.querySelectorAll('[attr-ext-link-type="twitterUrl"]');
                if (twitterLinks.length > 0) {
                    twitterLinks.forEach(eachTwitter => {
                        let twitterLink = eachTwitter?.getAttribute('href');
                        if (twitterLink && twitterLink.includes('/status/') && !this.iframeLinks.includes(twitterLink)) {
                            const twitterId = twitterLink.split('/').pop(); // Get the last segment of the URL
                            if (twitterId) {
                                // Create a blockquote for embedding the tweet
                                const embededTwitterHtml = `
                                <blockquote class="twitter-tweet">
                                    <a style="" href="${twitterLink}">Loading...</a>
                                </blockquote>
                            `;

                                const tweetContainer = document.createElement('div');
                                tweetContainer.classList.add('fig');
                                tweetContainer.innerHTML = embededTwitterHtml;
                                eachTwitter.appendChild(tweetContainer);
                                // const tweetContainer1 = document.createElement('div');
                                // tweetContainer1.appendChild(tweetContainer);
                                // eachTwitter.parentNode.appendChild(tweetContainer);
                                // Load Twitter's embed script
                                const existingScript = document.querySelector('script#twitter-wjs');
                                if (!existingScript) {
                                    const script = document.createElement('script');
                                    script.id = 'twitter-wjs';
                                    script.src = "https://platform.twitter.com/widgets.js";
                                    script.onload = () => {

                                        if ((window as any).twttr && (window as any).twttr.widgets) {
                                            (window as any).twttr.widgets.load();
                                        }
                                    };
                                    document.body.appendChild(script);
                                } else {
                                    // If the script already exists, reload widgets
                                    setTimeout(() => {
                                        if ((window as any).twttr && (window as any).twttr.widgets) {
                                            (window as any).twttr.widgets.load();
                                        }
                                    }, 500);
                                }

                                // Hide any elements with the class "disp-quote"
                                // const dispQuotes = document.querySelectorAll('.disp-quote');
                                // dispQuotes.forEach(eachQuote => {
                                //     eachQuote.classList.add('hide');
                                // });
                            }
                        }
                    });
                }

                //for loading vimeo widgets bjs-academyUrl
                const bjs_url = html.querySelectorAll('[attr-ext-link-type="bjs-academyUrl"]');
                if (bjs_url.length > 0) {
                    bjs_url.forEach(eachUrl => {
                        const vimeoLink = eachUrl?.getAttribute('href');
                        if (vimeoLink && !this.iframeLinks.includes(vimeoLink)) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="bjsclass" scrolling="no" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele?.classList.add('link');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachUrl.appendChild(divele);
                            }
                            // eachImage.classList.remove('fig');
                            // eachImage.classList.add('link');
                        }

                    })
                }

                //for loading vimeo widgets Url
                const url = html.querySelectorAll('[attr-ext-link-type="Url"]');
                if (url.length > 0) {
                    url.forEach(eachUrl => {
                        const vimeoLink = eachUrl?.getAttribute('href');
                        if (vimeoLink && !this.iframeLinks.includes(vimeoLink)) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele?.classList.add('link');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachUrl.appendChild(divele);
                            }
                            // eachImage.classList.remove('fig');
                            // eachImage.classList.add('link');
                        }

                    })
                }

                //for loading vimeo widgets
                const vimeo = html.querySelectorAll('[attr-ext-link-type="vimeoUrl"]');
                if (vimeo.length > 0) {
                    vimeo.forEach(eachVimeo => {
                        const vimeoLink = eachVimeo?.getAttribute('href');
                        if (vimeoLink && !this.iframeLinks.includes(vimeoLink)) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele?.classList.add('link');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachVimeo.appendChild(divele);
                            }
                            // eachImage.classList.remove('fig');
                            // eachImage.classList.add('link');
                        }
                    });
                }

                //for spotify
                const spotify = html.querySelectorAll('[attr-ext-link-type="anchor-fm-incUrl"]');
                if (spotify.length > 0) {
                    spotify.forEach(eachSpotify => {
                        const spotifyLink = eachSpotify?.getAttribute('href');
                        if (spotifyLink && !this.iframeLinks.includes(spotifyLink)) {
                            const spotify_link_array = spotifyLink.split('/');
                            const spotify_id = spotify_link_array['4'];
                            if (spotify_id !== undefined) {
                                const embeded_spotify_html = `
                                    <iframe 
                                        class="wp-embedded-content spotifyclass" 
                                        security="restricted" 
                                        title="Spotify Player" 
                                        src="${spotifyLink}" 
                                        data-secret="2SKUdoXe0b" 
                                        height="205px" 
                                        width="400px" 
                                        frameborder="0" 
                                        scrolling="no" 
                                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                                        allowtransparency="true"
                                        style="border:none; overflow:hidden;">
                                    </iframe>`;

                                const divele = document.createElement('div');
                                divele.classList.add('spotify_widget');
                                divele?.classList.add('link');
                                divele.innerHTML = embeded_spotify_html;
                                eachSpotify.appendChild(divele);
                            }

                            // eachImage.classList.remove('fig');
                            // eachImage.classList.add('link');
                        }

                    })
                }

                //for loading youtube videos
                const youtubeLinks = html.querySelectorAll('[attr-ext-link-type="youtubeUrl"]');
                youtubeLinks.forEach(eachYouTube => {
                    const vimeoLink = eachYouTube?.getAttribute('href');
                    if (vimeoLink && !this.iframeLinks.includes(vimeoLink)) {
                        const vimeo_link_array = vimeoLink.split('/');
                        const vimeo_id = vimeo_link_array['4'];

                        if (vimeo_id != undefined) {
                            const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                            const divele = document.createElement('div');
                            divele?.classList.add('vimeo_widget');
                            divele?.classList.add('link');
                            divele.innerHTML = embeded_vimeo_html;
                            // eachVimeo.innerHTML = embeded_vimeo_html;
                            eachYouTube.appendChild(divele);
                        }
                        // eachImage.classList.remove('fig');
                        // eachImage.classList.add('link');
                    }
                });

                //to show bullet
                const bullet_tag = html.querySelectorAll("[attr-list-type='bullet']");
                bullet_tag.forEach(each_bullet => {
                    const ul = document.createElement('ul');
                    ul.setAttribute('id', each_bullet.id);
                    ul.setAttribute('attr-list-type', 'bullet');
                    ul.className = 'list';

                    // Find all list-item divs and convert them to <li>
                    const listItems = each_bullet.querySelectorAll('.list-item');
                    listItems.forEach(listItem => {
                        const li = document.createElement('li');
                        li.className = 'list-item';

                        // Move the content from the list-item to the <li>
                        while (listItem.firstChild) {
                            li.appendChild(listItem.firstChild);
                        }

                        ul.appendChild(li);
                    });

                    // Replace the old list div with the new <ul>
                    each_bullet.replaceWith(ul);
                });



                // to restructure reference tag
                const back_tag = html.querySelector("[class='ref-list']");
                // Find the span element within each_tag
                const ref_title = back_tag?.querySelector('div.title') as HTMLElement;
                ref_title?.classList.add('ref-title');

                // Get all reference items with class 'p'
                const refItems = back_tag?.querySelectorAll('div.p') as NodeListOf<HTMLElement>;

                // Create an <ol> element for ordered list
                const olElement = document.createElement('ol');

                // Loop through each 'div.p' and convert it to 'li'
                refItems?.forEach((refItem) => {
                    if (refItem.textContent == '') {
                        refItem.remove();
                    }
                    else {
                        const liElement = document.createElement('li');
                        liElement.innerHTML = refItem.innerHTML; // Copy text content
                        olElement.appendChild(liElement); // Add each 'li' to 'ol'
                        refItem.remove(); // Remove original 'div.p' element
                    }
                });                


                // Get all reference items with class 'ref'
                const refItemsNew = back_tag?.querySelectorAll('div.ref') as NodeListOf<HTMLElement>;

                // Create an <ol> element for ordered list
                const olElementNew = document.createElement('ol');

                // Loop through each 'div.p' and convert it to 'li'
                refItemsNew?.forEach((refItem) => {
                    let mixed = refItem.querySelector('span.mixed-citation')

                    if (mixed.querySelector('span')?.textContent == '') {
                        mixed.remove();
                    }
                    else {
                        const liElement = document.createElement('li');
                        liElement.innerHTML = mixed.innerHTML; // Copy inner HTML to retain structure
                        olElementNew.appendChild(liElement); // Add each 'li' to 'ol'
                        refItem.remove(); // Remove original 'div.p' element
                    }
                });

                // Append the new ordered list to 'back_tag'
                back_tag?.appendChild(olElementNew);

                // Append the new ordered list to 'back_tag'
                back_tag?.appendChild(olElement);


                //span break tags
                const break_tag = html.querySelectorAll("[attr-specific-use='break']")
                // Replace each with a <br> tag
                break_tag.forEach(each_break_tag => {
                    const br = document.createElement('br');
                    each_break_tag.parentNode?.replaceChild(br, each_break_tag);
                });

                //find links to show some pdf button
                const link = html?.querySelectorAll('a.ext-link');
                link?.forEach(eachLink => {
                    const download_url = eachLink?.getAttribute('href');
                    let download_array = [];
                    if (download_url.includes('www.bjsacademy.com') && download_url.endsWith('.pdf')) {
                        download_array = download_url.split('/');
                        if (download_array.length > 0) {
                            const length = download_array?.length;
                            this.download_pdf = download_array[length - 1];
                            const downloadArray: download = {
                                downloadText: eachLink?.textContent,
                                downloadUrl: env.articleImage + this.articleFilePath + '/' + this.download_pdf
                            };

                            // Check if downloadUrl already exists in download_url array
                            if (!this.download_url.some(item => item.downloadUrl === downloadArray.downloadUrl)) {
                                this.download_url.push(downloadArray);
                            }

                            // this.download_url = env.articleImage + this.articleFilePath + '/' + this.download_pdf;
                        }
                        eachLink.setAttribute('style', 'display: none;');
                    }
                })

                //find ref link from ref tag
                const ref_link = back_tag?.querySelectorAll('a.ext-link');
                ref_link?.forEach(eachLink => {
                    eachLink.classList.add('ref-link');
                    const span_ref_link = eachLink.querySelector('span.bold');
                    span_ref_link?.classList.remove('bold');
                    span_ref_link?.classList.remove('section-title');
                })

                //to remove section-title
                const span_tag = back_tag?.querySelectorAll('span.section-title');
                span_tag?.forEach(eachSpan => {
                    eachSpan?.classList.remove('section-title');
                })

                // Ensure all external links open in new tab
                const externalLinks = html.querySelectorAll('a.ext-link');
                if (externalLinks.length > 0) {
                    externalLinks.forEach(link => {
                        link.setAttribute('target', '_blank');
                    });
                }


                const serializer = new XMLSerializer();
                this.html_new_content = serializer.serializeToString(html);
                this.html_new_content_dupe = this.sanitizer.bypassSecurityTrustHtml(this.html_new_content);
                this.articleContetLoading = false;
            }
        }

    }

    restructureAbstarct() {
        if (this.articleView?.abstract && isPlatformBrowser(this.platformId)) {

            // Create a temporary HTML element
            const html = document.createElement('div');

            // Assign the abstract string as the innerHTML
            html.innerHTML = this.articleView?.abstract || '';

            //restructuring images
            const fig_tags = html.querySelectorAll("fig");

            Array.from(fig_tags).forEach((eachImage) => {

                //for loading vimeo widgets bjs-academyUrl
                const bjs_url = eachImage.querySelectorAll('[ext-link-type="bjs-academyUrl"]');
                if (bjs_url.length > 0) {
                    bjs_url.forEach(eachUrl => {
                        const vimeoLink = eachUrl?.getAttribute('xlink:href');
                        if (vimeoLink) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="bjsclass" scrolling="no" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachUrl.appendChild(divele);
                            }
                            eachImage.classList.remove('fig');
                            eachImage.classList.add('link');
                        }

                    })
                }

                //for loading vimeo widgets Url
                const url = eachImage.querySelectorAll('[ext-link-type="Url"]');
                if (url.length > 0) {
                    url.forEach(eachUrl => {
                        const vimeoLink = eachUrl?.getAttribute('xlink:href');
                        if (vimeoLink) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachUrl.appendChild(divele);
                            }
                            eachImage.classList.remove('fig');
                            eachImage.classList.add('link');
                        }

                    })
                }

                //for loading vimeo widgets
                const vimeo = eachImage.querySelectorAll('[ext-link-type="vimeoUrl"]');
                if (vimeo.length > 0) {
                    vimeo.forEach(eachVimeo => {
                        const vimeoLink = eachVimeo?.getAttribute('xlink:href');
                        if (vimeoLink) {
                            const vimeo_link_array = vimeoLink.split('/');
                            const vimeo_id = vimeo_link_array['4'];

                            if (vimeo_id != undefined) {
                                const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                                const divele = document.createElement('div');
                                divele?.classList.add('vimeo_widget');
                                divele.innerHTML = embeded_vimeo_html;
                                // eachVimeo.innerHTML = embeded_vimeo_html;
                                eachVimeo.appendChild(divele);
                            }
                            eachImage.classList.remove('fig');
                            eachImage.classList.add('link');
                        }
                    });
                }

                //for spotify
                const spotify = eachImage.querySelectorAll('[ext-link-type="anchor-fm-incUrl"]');

                if (spotify.length > 0) {
                    spotify.forEach(eachSpotify => {
                        const spotifyLink = eachSpotify?.getAttribute('xlink:href');
                        if (spotifyLink) {
                            const spotify_link_array = spotifyLink.split('/');
                            const spotify_id = spotify_link_array['4'];
                            if (spotify_id !== undefined) {
                                const embeded_spotify_html = `
                                    <iframe 
                                        class="wp-embedded-content rctspotifyclass" 
                                        security="restricted" 
                                        title="Spotify Player" 
                                        src="${spotifyLink}" 
                                        data-secret="2SKUdoXe0b" 
                                        height="205px" 
                                        width="400px" 
                                        frameborder="0" 
                                        scrolling="no" 
                                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                                        allowtransparency="true"
                                        style="border:none; overflow:hidden;">
                                    </iframe>`;

                                const divele = document.createElement('div');
                                divele.classList.add('spotify_widget');
                                divele.innerHTML = embeded_spotify_html;
                                eachSpotify.appendChild(divele);
                            }

                            eachImage.classList.remove('fig');
                            eachImage.classList.add('link');
                            const altText: any = eachImage.querySelector('alt-text');
                            altText.style.display = 'none'; // Hides the image
                        }

                    })
                }

                //for loading twitter links
                const twitter = eachImage.querySelectorAll('[ext-link-type="twitterUrl"]');
                if (twitter.length > 0) {
                    twitter.forEach(eachTwitter => {
                        let twitterLink: any = eachTwitter?.getAttribute('xlink:href');
                        if (twitterLink) {
                            const twitter_link_array = twitterLink.split('/');
                            const length = twitter_link_array?.length;
                            const twitter_id = twitter_link_array[length - 1];
                            if (twitter_id !== undefined) {
                                // Create a blockquote for embedding the tweet
                                const embeded_twitter_html = `
                                <blockquote class="twitter-tweet">
                                    <a href="${twitterLink}"></a>
                                </blockquote>
                            `;
                                const divele = document.createElement('div');
                                divele.innerHTML = embeded_twitter_html;
                                eachImage.appendChild(divele);

                                // Load Twitter's embed script
                                if (!document.querySelector('script#twitter-wjs')) {
                                    const script = document.createElement('script');
                                    script.id = 'twitter-wjs';
                                    script.src = "https://platform.twitter.com/widgets.js";
                                    script.onload = () => {
                                        (window as any).twttr.widgets.load();
                                    };
                                    document.body.appendChild(script);
                                } else {
                                    // If script already exists, reload widgets after a short delay
                                    setTimeout(() => {
                                        if ((window as any).twttr && (window as any).twttr.widgets) {
                                            (window as any).twttr.widgets.load();
                                        }
                                    }, 500);
                                }

                                const disp_quote = eachImage.querySelectorAll('[class="disp-quote"]');
                                disp_quote.forEach(each_quote => {
                                    each_quote.classList.add('hide');
                                })
                            }
                            // eachImage.classList.remove('fig');
                        }
                    });
                }

                //for loading youtube videos
                const youtubeLinks = eachImage.querySelectorAll('[ext-link-type="youtubeUrl"]');
                youtubeLinks.forEach(eachYouTube => {
                    const vimeoLink = eachYouTube?.getAttribute('xlink:href');
                    if (vimeoLink) {
                        const vimeo_link_array = vimeoLink.split('/');
                        const vimeo_id = vimeo_link_array['4'];

                        if (vimeo_id != undefined) {
                            const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
                            const divele = document.createElement('div');
                            divele?.classList.add('vimeo_widget');
                            divele.innerHTML = embeded_vimeo_html;
                            // eachVimeo.innerHTML = embeded_vimeo_html;
                            eachYouTube.appendChild(divele);
                        }
                        eachImage.classList.remove('fig');
                        eachImage.classList.add('link');
                    }
                });

            });

            //recreating html............
            const serializer = new XMLSerializer();
            this.articleAbstract = serializer.serializeToString(html);
            this.articleAbstract_new = this.sanitizer.bypassSecurityTrustHtml(this.articleAbstract);

        }
    }

    openDyslexic(mode: string) {
        this.activeViewFont = mode;
        if (mode == 'dyslexic') this.contentLoaded.nativeElement.classList.add('dyslexic-regular');
        else if (mode == 'normal') this.contentLoaded.nativeElement.classList.remove('dyslexic-regular');

    }

    // openDownloadLink() {
    //     window.open(this.download_url, '_blank');
    // }

    //to display modal and to navigate to reference
    refClick(event: Event) {
        const aTagEle = event.target as HTMLInputElement;
        const refAttrVal = aTagEle?.getAttribute("attr-ref-type") as string;
        this.div_data = null;

        if (aTagEle.classList.contains('graphic')
        ) {  // Check if the clicked element has the 'graphic' class
            const href: string = aTagEle.getAttribute('href') as string;
            const result = href.substring(0, href.indexOf('.')); // Extract the content before the first period

            const figElements = document.querySelectorAll(".fig"); // Query all elements with class 'fig'

            if (figElements.length > 0) {
                figElements.forEach((eachFig) => {
                    const imgElement = eachFig.querySelector('img.graphic');
                    if (imgElement) {
                        const imgHref = imgElement.getAttribute('href');
                        if (imgHref && imgHref.includes(result)) {
                            this.div_data = eachFig; // Store the reference to the matched fig element
                        }
                    }
                });

            }
            else {
                this.div_data = aTagEle;
            }


            if (this.div_data) {
                // Clone the div_data to ensure the original remains in the DOM
                this.div_data = this.div_data.cloneNode(true) as HTMLElement;
                this.isModalOpen = true;
            }
        }

        if (aTagEle.classList.contains('inline-graphic')
        ) {  // Check if the clicked element has the 'graphic' class
            const href: string = aTagEle.getAttribute('href') as string;
            const result = href.substring(0, href.indexOf('.')); // Extract the content before the first period

            const figElements = document.querySelectorAll(".fig"); // Query all elements with class 'fig'

            // if (figElements.length > 0) {
            //     figElements.forEach((eachFig) => {
            //         const imgElement = eachFig.querySelector('img.graphic');
            //         if (imgElement) {
            //             const imgHref = imgElement.getAttribute('href');
            //             if (imgHref && imgHref.includes(result)) {
            //                 this.div_data = eachFig; // Store the reference to the matched fig element
            //             }
            //         }
            //     });

            // }
            // else {
            this.div_data = aTagEle;
            // }


            if (this.div_data) {
                // Clone the div_data to ensure the original remains in the DOM
                this.div_data = this.div_data.cloneNode(true) as HTMLElement;
                this.isModalOpen = true;
            }
        }
    }

    loadModal(event: Event) {

        const aTagEle = event.target as HTMLInputElement;
        this.div_data = aTagEle;
        if (this.div_data) {
            // Clone the div_data to ensure the original remains in the DOM
            this.div_data = this.div_data.cloneNode(true) as HTMLElement;
            this.isModalOpen = true;
        }
    }


    closeJoinModal() {
        this.isModalOpen = false;
    }

    showContent(section: string) {
        this.activeSection = section;
    }
    setActiveTab(tab: string) {
        this.activeTab = tab;

    }
    // onTagClick(tag: { type: string, value: string }) {

    //     const queryParams: any = {
    //         // search: this.searchValue
    //     };

    //     if (tag.type === 'contentType') {
    //         queryParams['contentType'] = tag.value;
    //     } else if (tag.type === 'speciality') {
    //         queryParams['speciality'] = tag.value;
    //     } else if (tag.type === 'category') {
    //         queryParams['category'] = tag.value;
    //     }

    //     let path: string[];
    //     if (['social-media', 'randomized-clinical-trials', 'surgical-science', 'surgery-for-all'].includes(this.category)) {
    //         path = ['/bjs-academy', 'scientific-surgery', this.category, 'page', '1'];
    //     }
    //     this.router.navigate(path, {
    //         queryParams: queryParams,
    //         queryParamsHandling: 'merge'
    //     }).then(() => {
    //     }).catch(err => {
    //     });
    //     // this.router.navigate(['/bjs-academy', this.category, 'page', '1'], { queryParams });

    // }
    onTagClick(tag: { type: string, value: string }) {
        const queryParams: any = {};
    
        if (tag.type === 'contentType') {
            queryParams['contentType'] = tag.value;
        } else if (tag.type === 'speciality') {
            queryParams['speciality'] = tag.value;
        } else if (tag.type === 'category') {
            queryParams['category'] = tag.value;
        }
    
        // Prepare path based on category
        let path: string[];
    
        // Check if category is part of your predefined list
        if (['social-media', 'randomized-clinical-trials', 'surgical-science', 'surgery-for-all'].includes(this.category)) {
            path = ['/bjs-academy', 'scientific-surgery', this.category, 'page', '1'];
        } else {
            // Default path (this can be adjusted depending on your routing)
            path = ['/bjs-academy', this.category, 'page', '1'];
        }
    
        // Perform navigation
        this.router.navigate(path, {
            queryParams: queryParams,
            queryParamsHandling: 'merge' // This ensures existing query params are kept
        }).then(() => {
            // Optionally handle successful navigation (e.g., loading state)
        }).catch(err => {
            // Optionally handle errors during navigation
          //  console.error('Navigation error:', err);
        });
    }
    
    onScroll(event: any) {
        const scrollPosition = window.scrollY;
        const targetElement = document.getElementById('article-info-section');
        const containerElement = targetElement?.parentElement;
        const endContentElement = document.getElementById('article-content'); // The right column to detect when scrolling past it

        if (containerElement && endContentElement) {
            const targetPosition = containerElement.offsetTop;
            const endPosition = endContentElement.offsetHeight;
            this.containerWidth = containerElement.offsetWidth;

            // Check if the user has scrolled past the container but not beyond the main content
            if (scrollPosition >= targetPosition && scrollPosition <= endPosition) {
                this.isFixed = true; // Apply fixed position
            } else {
                this.isFixed = false; // Remove fixed position
            }
        }
    }

    ngOnDestroy() {
        this.destroyMetaTagsforSEONew();
        this.subscriptions.forEach(x => {
            if (!x.closed) {
                x.unsubscribe();
            }
        });
    }
    goto(url: string) {
        this.router.navigate([url])
    }
    gotonav(url: string) {
        const navpath = "/bjs-academy/" + url
        this.router.navigate([navpath])
    }

    formatText(text: string, title: string): string {
        if (text) {
            if (text == 'young-bjs') {
                return text.replace(/(\w+)-(\w+)/, (match, p1, p2) => {
                    const firstPart = p1.charAt(0).toUpperCase() + p1.slice(1); // Capitalize first word
                    const secondPart = p2.toUpperCase(); // Convert second part to uppercase
                    return `${firstPart} ${secondPart}`;
                });
            }
            else {
                const formattedText = text.replace(/-/g, ' '); // Replace all hyphens with spaces
                const capitalizedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1); // Capitalize the first letter
                if(title!='title'){
                    const returnText = capitalizedText.length > 20 ? capitalizedText.substring(0, 20) + '...' : capitalizedText;
                    return returnText;
                }
                return capitalizedText;
            }
        }
        return null;
    }
    addOrRemoveArticleLike() {
        this.isArticleLiked = !this.isArticleLiked;
        const action = !this.isArticleLiked ? 'decrement' : 'increment';
        const queryParams = {
            journal_id: this.journal_id,
            category: this.category,
            article_id: this.articleView?.article_id,
            action: action

        };
        const likeUpdateSub = this.articleService.updateLikeCount(queryParams).subscribe(response => {
            this.formattedLikeCount = this.formatLikeCount(response?.like_count);
            this.showLikeCount = response?.like_count > 0 ? true : false;
        })
        this.subscriptions.push(likeUpdateSub);
        let articleLikeCount = JSON.parse(localStorage.getItem('articleLikeCount') || '[]');
        if (this.isArticleLiked) {
            articleLikeCount.push({ article_id: this.articleView?.article_id, article_liked: true });
        } else {
            articleLikeCount = articleLikeCount.filter((article: any) => article?.article_id !== this.articleView?.article_id);
        }
        localStorage.setItem('articleLikeCount', JSON.stringify(articleLikeCount));
    }
    formatLikeCount(count: number): string {
        if (count >= 1000) {
            const formattedCount = (count / 1000);
            return formattedCount % 1 === 0 ? `${formattedCount.toFixed(0)}k` : `${formattedCount.toFixed(1)}k`;
        }
        return count?.toString();
    }
    checkIfArticleIsLiked(article_id: string) {
        if (typeof window !== 'undefined' && window.localStorage) {
            const articleLikeCount = JSON.parse(localStorage.getItem('articleLikeCount') || '[]');
            this.isArticleLiked = articleLikeCount.some((article: any) => article?.article_id === article_id && article.article_liked === true);
        } else {
            this.isArticleLiked = false;
        }
    }
    returnAuthor(author_name: string) {
        return this.sanitizer.bypassSecurityTrustHtml(author_name);
    }
    sanitizeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    returnNotes(note:string){ 
        if(note){
            return note?.replace(/<ext-link xlink:href=/g, '<a class="ext-link" href=')
            .replace(/<\/ext-link>/g, '</a>');
        } else{
            return '';
        }
    }
}