<div class="row row-cols-1 row-cols-lg-2 row-cols-md-2  g-4 ps-xxl-1 ps-xl-1 ps-lg-1 ps-md-1 ps-sm-0 pe-xxl-1 pe-xl-1 pe-lg-1 pe-md-1 pe-sm-0 top-article-card-row" *ngIf="!pageloading;else PageTopNotLoaded">
    <!-- top data -->
    <div *ngIf="top_rated" class="col  max-width-card-right" #maindiv>
        <div class="card sticky-pos">
            <span class="book-mark-badge">
                <div class="ribbon ribbbon-left">
                    <p class="badge-text pt-1">TOP ARTICLE</p>
                    <p class="badge-text">OF THE </p>
                    <p class="badge-text">MONTH</p>
                </div>
            </span>
            <a [routerLink]="['/bjs-academy/'+top_rated.category_heading.id+'/'+top_rated.article_link]" class="article_cover_img_card">
                <img src="{{returnCoverImage(top_rated)}}" class="card-img-top" alt="cover image">
            </a>
            <div class="card-body">
                <div class="article-data">
                    <a class="h5-a" [routerLink]="['/bjs-academy/'+top_rated.category_heading.id+'/'+top_rated.article_link]">
                    <h5 class="card-title" [innerHtml]="returnSummary(top_rated?.article_title)"></h5></a>
                    <p class="authors-name" *ngIf="top_rated?.authors && top_rated?.authors.length > 0">
                        <!-- Use helper methods to check the type of authors array -->
                        <span *ngIf="isStringArray(top_rated?.authors)" [innerHtml]="top_rated?.authors.join(', ')">
                        </span>
                        <span *ngIf="isObjectArray(top_rated?.authors)">
                            <span *ngFor="let author of top_rated?.authors; let isLast = last" [innerHtml]="author.name"><span *ngIf="!isLast">, </span>
                            </span>
                        </span>
                    </p>
                    <p class="card-text" tabindex="0" [innerHtml]="returnSummary(top_rated?.article_summary)"></p>
                </div>

              <div class="d-flex justify-content-between align-items-center">
                  <!-- <div class="row show-less"> -->
                    <div class="card-tag" #content>
                        <span *ngFor="let tag of top_rated.surgical_speciality"
                            class=" badge rounded-pill bg-primary article-badge">
                            <a class="badge-link" [innerHTML]="tag"
                                (click)="filterClicked(tag, 'speciality');highlightReference(tag)">
                            </a>
                        </span>
                        <span *ngFor="let type of top_rated.content_type"
                            class=" badge rounded-pill bg-primary article-badge">
                            <a class="badge-link" [innerHTML]="type"
                                (click)="filterClicked(type, 'type');highlightType(type)">
                            </a>
                        </span>
                        <span *ngIf="returnCategory(top_rated.category_heading.id)"
                            class=" badge rounded-pill bg-primary article-badge">
                            <a class="badge-link" [innerHTML]="top_rated.category_heading.title"
                                (click)="categoryClick(top_rated.category_heading.id,top_rated.category_heading.title)">
                            </a>
                        </span>
                    </div>
                    <!-- (click)="toggleShowMore(maindiv,$event)" -->
                    <div class="txtcol" #showMore (click)="toggleShowMore(maindiv,$event)" ><a>More...</a></div>
                    <!-- </div> -->
                    
              </div>
              <div class="d-flex justify-content-between pt-2">
                <div class="like-button">
                    <div class="badge-container">
                        <fa-icon [icon]="['far', 'thumbs-up']" class="share-icon" *ngIf="!isTOPArticleLiked"
                            (click)="addOrRemoveArticleLike(top_rated.journal_id,top_rated.category_heading.id,top_rated?.article_id,'top')"></fa-icon>
                        <fa-icon [icon]="['fas', 'thumbs-up']" class="share-icon" *ngIf="isTOPArticleLiked"
                            (click)="addOrRemoveArticleLike(top_rated.journal_id,top_rated.category_heading.id,top_rated?.article_id,'top')"></fa-icon>
                        <span  class="badge" *ngIf="showTopLikeCount">({{ formattedTOPLikeCount }})</span>

                    </div>
                </div>
                <div class="pub-date " tabindex="0">
                    <fa-icon [icon]="['fas','calendar-days']" class="me-2"></fa-icon>
                    {{top_rated?.published_online}}
                </div>
              </div>
            </div>
        </div>
    </div>

    <!-- rct data -->
    <div *ngIf="rct_data" class="col max-width-card-left" #maindivsecond>
        <div class="card sticky-pos">
            <span class="book-mark-badge-right">
                <div class="ribbon ribbbon-right">
                    <p class="badge-text-right pt-1">RCT </p>
                    <p class="badge-text-right">OF THE </p>
                    <p class="badge-text-right">MONTH</p>
                </div>
            </span>
            <a [routerLink]="['/bjs-academy/'+rct_data.category_heading.id+'/'+rct_data.article_link]" class="article_cover_img_card">
                <img src="{{returnCoverImage(rct_data)}}"class="card-img-top" alt="cover image">
            </a>
            <div class="card-body">
                <div class="article-data">
                    <a class="h5-a" [routerLink]="['/bjs-academy/'+rct_data.category_heading.id+'/'+rct_data.article_link]">
                        <h5 class="card-title large-text" [innerHtml]="rct_data?.article_title">
                        </h5>
                    </a>
                    <p class="authors-name" *ngIf="rct_data?.authors && rct_data?.authors.length > 0">
                        <!-- Use helper methods to check the type of authors array -->
                        <span *ngIf="isStringArray(rct_data?.authors)" [innerHtml]=" rct_data?.authors.join(', ') ">
                        </span>
                        <span *ngIf="isObjectArray(rct_data?.authors)">
                            <span *ngFor="let author of rct_data?.authors; let isLast = last" [innerHtml]="author.name"><span *ngIf="!isLast">, </span>
                            </span>
                        </span>
                    </p>
                    <p *ngIf="rct_data?.abstract" class="card-text" tabindex="0" [innerHtml]="returnSummary(rct_data?.abstract)"></p>
                    <p *ngIf="!rct_data?.abstract && rct_data?.article_summary" class="card-text" tabindex="0" [innerHtml]="html_new_content_dupe"></p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="card-tag" #cardTag [class.show-all]="isExpanded">
                        <span *ngFor="let tag of rct_data.surgical_speciality" class="badge rounded-pill bg-primary article-badge">
                          <a class="badge-link" [innerHTML]="tag" (click)="filterClicked(tag, 'speciality');highlightReference(tag)"></a>
                        </span>
                        <span *ngFor="let type of top_rated?.content_type" class="badge rounded-pill bg-primary article-badge">
                          <a class="badge-link" [innerHTML]="type" (click)="filterClicked(type, 'type');highlightType(type)"></a>
                        </span>
                        <span *ngIf="returnCategory(rct_data.category_heading.id)" class="badge rounded-pill bg-primary article-badge">
                          <a class="badge-link" [innerHTML]="rct_data.category_heading.title"
                             (click)="categoryClick(rct_data.category_heading.id,rct_data.category_heading.title)"></a>
                        </span>
                      </div>
                      
                      <div class="txtcol" #showMore (click)="toggleShowMore(maindivsecond,$event)" ><a>More...</a></div>
                      
                  
                   
                  </div>
                  <div class="d-flex justify-content-between pt-2">
                    <div class="like-button">
                        <div class="badge-container">
                            <fa-icon [icon]="['far', 'thumbs-up']" class="share-icon" *ngIf="!isRCTArticleLiked"
                                (click)="addOrRemoveArticleLike(rct_data.journal_id,rct_data.category_heading.id,rct_data?.article_id,'rct')"></fa-icon>
                            <fa-icon [icon]="['fas', 'thumbs-up']" class="share-icon" *ngIf="isRCTArticleLiked"
                                (click)="addOrRemoveArticleLike(rct_data.journal_id,rct_data.category_heading.id,rct_data?.article_id,'rct')"></fa-icon>
                            <span  class="badge" *ngIf="showRCTLikeCount">({{ formattedRCTLikeCount }})</span>
    
                        </div>
                    </div>
                    <div class="pub-date " tabindex="0">
                        <fa-icon [icon]="['fas','calendar-days']" class="me-2"></fa-icon>
                        {{rct_data?.published_online}}
                      </div>
                  </div>
                  
              
            </div>
        </div>
    </div>
</div>
<ng-template #PageTopNotLoaded>
    <div class="row row-cols-1 row-cols-lg-2 row-cols-md-2  g-4 ps-xxl-1 ps-xl-1 ps-lg-1 ps-md-1 ps-sm-0 pe-xxl-1 pe-xl-1 pe-lg-1 pe-md-1 pe-sm-0">
        <div class="col  max-width-card-right">
            <div class="card sticky-pos">

                <div class="card-img-top skeleton" width="225" height="336" alt="cover image"></div>
                <div class="card-body">

                    <h5 class="skeleton-card-title skeleton skeleton-text "></h5>
                    <p class="skeleton-author skeleton skeleton-text skeleton-text-80"></p>
                    <p class=" skeleton skeleton-text"></p>
                    <p class=" skeleton skeleton-text"></p>
                    <p class=" skeleton skeleton-text"></p>
                    <p class=" skeleton skeleton-text skeleton-text-80 "></p>
                    <div class="row show-less">
                        <div class="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-10 ">
                            <div class="badges ml-2 overflow ">
                                <div class=" badge rounded-pill bg-primary article-badge skeleton skeleton-badge">
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

                                </div>
                                <div class=" badge rounded-pill bg-primary article-badge skeleton skeleton-badge">
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                </div>

                            </div>

                        </div>

                    </div>


                    <div class="pub-date d-flex justify-content-end skeleton skeleton-date" tabindex="0">
                        &nbsp; &nbsp;
                    </div>
                </div>
            </div>
        </div>
        <div class="col max-width-card-left">
            <div class="card ">

                <div class="card-img-top skeleton" width="225" height="336" alt="cover image"></div>
                <div class="card-body">

                    <h5 class="skeleton-card-title skeleton skeleton-text "></h5>
                    <p class="skeleton-author skeleton skeleton-text skeleton-text-80"></p>
                    <p class=" skeleton skeleton-text"></p>
                    <p class=" skeleton skeleton-text"></p>
                    <p class=" skeleton skeleton-text"></p>
                    <p class=" skeleton skeleton-text skeleton-text-80 "></p>
                    <div class="row show-less">
                        <div class="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-10 ">
                            <div class="badges ml-2 overflow ">
                                <div class=" badge rounded-pill bg-primary article-badge skeleton skeleton-badge">
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

                                </div>
                                <div class=" badge rounded-pill bg-primary article-badge skeleton skeleton-badge">
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                </div>

                            </div>

                        </div>

                    </div>


                    <div class="pub-date mt-auto d-flex justify-content-end skeleton skeleton-date" tabindex="0">
                        &nbsp; &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>