<app-home-banner [pageType]="'homepage'" [BannerHeading]="bannerHeading" [BannerImage]="bannerImage"></app-home-banner>
<section id="top-articles" class="container-fluid p-0">
    <div class="top-articles" [ngStyle]="{'padding-bottom':billboard?'18rem':'5rem'}">
        <div class="section-heading" tabindex="0" *ngIf="!pageloading;else PageTitleNotLoaded">
            <h1> What's new</h1>
        </div>
        <ng-template #PageTitleNotLoaded>
            <div class="section-heading skeleton" tabindex="0">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
        </ng-template>
        <app-top-rated *ngIf="!pageTopRatedLoading;else PageTopRatedNotLoaded"
            [pageloading]="pageTopRatedLoading"></app-top-rated>
        <ng-template #PageTopRatedNotLoaded>
            <app-top-rated [pageloading]="pageTopRatedLoading"></app-top-rated>
        </ng-template>
        <div class="row mt-xxl-1 mt-xl-3 mt-lg-3 mt-md-4 mt-sm-4 mt-xs-4 d-flex justify-content-center"
            *ngIf="!articlePageLoading;else PageArticleNotLoaded">
            <div *ngFor="let article of articles; let i = index"
                class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 card-gap ps-xxl-3 ps-xl-3 ps-lg-3 ps-md-3 ps-sm-0 "
                [ngClass]="i == 0 || i == 4 ? 'ps-xxl-3 ps-xl-3 ps-lg-3 ps-md-3 ps-sm-0' : (i == 3 || i == 7 ? 'pe-xxl-3 pe-xl-3 pe-lg-3 pe-md-3 pe-sm-2' : '')">
                <app-article-card-list [article]="article" [pageType]="'homepage'" [index]="i"></app-article-card-list>

            </div>

        </div>
        <ng-template #PageArticleNotLoaded>
            <div class="row mt-xxl-1 mt-xl-3 mt-lg-3 mt-md-4 mt-sm-4 mt-xs-4 d-flex justify-content-center">
                <div *ngFor="let each of (articleListarray | slice:0:8); let i = index"
                    class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 card-gap ps-3  "
                    [ngClass]="i == 0 || i == 4 ? 'ps-3' : (i == 3 || i == 7 ? 'pe-3' : '')">
                    <app-article-card-list [pageloading]="articlePageLoading"></app-article-card-list>

                </div>
            </div>
        </ng-template>
    </div>

</section>
<section *ngIf="billboard" class="position-absolute billboard-section">
    <app-billboard (BillboardHeight)="getBillboardBoxWidth($event)"
        *ngIf="!billboardPageLoading; else pageBillBoaredNotLoaded"></app-billboard>
    <ng-template #pageBillBoaredNotLoaded>
        <app-billboard (BillboardHeight)="getBillboardBoxWidth($event)"
            [pageloading]="billboardPageLoading"></app-billboard>
    </ng-template>
</section>

<section id="about-section" class="container-fluid ps-0 pe-0"
    [ngStyle]="{'padding-top':aboutPadding==0 && billboard?'190px':aboutPadding+'px'}">
    <app-about *ngIf="!aboutPageLoader ; else PageAboutNotLoaded"></app-about>
    <ng-template #PageAboutNotLoaded>
        <app-about [pageloading]="aboutPageLoader"></app-about>
    </ng-template>
</section>