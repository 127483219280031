<section id="hero" class="hero">
  <div class="container-fluid ">
    <div  class="hero-bg" aria-label="BJS banner image" *ngIf="!contentloading; else  ContentNotLoading ">
      <div class="breadcrumb-sec"> <span class="breadcrumb-sec-1" (click)="goto('')" title="BJS Academy">BJS Academy</span>
        <span class="breadcrumb-sec-2">></span><span class="breadcrumb-sec-1"  title="Academy" (click)="goto('bjs-academy')">Academy</span>  <span class="breadcrumb-sec-2">></span>
        <span class="breadcrumb-sec-3" title="{{replaceHypen(category)}}">{{formatText(category)}}</span>
      </div>
      <div
        class="row  d-flex-md flex-md-column-reverse d-flex-xxl flex-xxl-row d-flex-xl flex-xl-row d-flex-lg flex-lg-row"
        *ngIf="pageType=='listpage'">
        <div>
          <div class="young-bjs" #bannerContentSectionDiv *ngFor="let content of categoryContent">

            <div class="title-section">
              <h1 class="young-bjs-title ">{{content.heading}}</h1>
              <!-- <span class="underline-half"></span> -->
              <hr class="hr hr--color hr--lg">
            </div>
            <span *ngIf="content.primarycontent" class="young-bjs-title-sub"
              [innerHTML]="content.primarycontent"></span>
            <span *ngIf="content.secondarycontent" class="young-bjs-des" [innerHTML]="content.secondarycontent"> </span>
          </div>

        </div>

      </div>

    </div>
    <ng-template #ContentNotLoading>
      <div class="hero-bg" aria-label="BJS banner image" [ngStyle]="getBackgroundStyle()" *ngIf="!pageloading ; else PageNotLoaded">
        <div
          class="row  d-flex-md  d-flex-xxl flex-xxl-row d-flex-xl flex-xl-row d-flex-lg flex-lg-row"
          *ngIf="pageType=='academy'">
          <div class="breadcrumb-sec"> <span class="breadcrumb-sec-1" (click)="goto('')" title="BJS Academy">BJS Academy</span>
            <span class="breadcrumb-sec-2">></span>
            <span class="breadcrumb-sec-3" title="Academy">Academy</span>
          </div>
          <div class="col-xxl-12">
            <div class="young-bjs text-center academy-banner" #bannerContentSectionDiv>
  
              <div class="title-section mt-0 ">
                <div class="">
                  <h1 class="banner-content text-center"> LEADING SURGICAL</h1>

                  <h1 class="banner-content text-center m-0"> EDUCATION</h1>
                </div>
  
              </div>
  
            </div>
  
          </div>
  
        </div>
  
      </div>
      <ng-template #PageNotLoaded>
        <div class="hero-bg skeleton skeleton-banner">
        </div>
      </ng-template>
    </ng-template>
   
    <div  *ngIf="pageType!='academy'" id="search" aria-label="Search section" class="search-section-box" #maindiv >
      <div class="row">
        <div class="col">
          <app-category [categoryArray]="categories" [pageType]="pageType" 
            (searchEvent)="onSearchEvent($event)" (emitCheck)="filterEmit($event)" ></app-category>
        </div>
      </div>
    </div>
  </div>
</section>