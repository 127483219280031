import { DatePipe, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Output, ViewChild, EventEmitter, AfterViewInit, OnInit, ChangeDetectorRef, HostListener, Inject, PLATFORM_ID, Input, OnDestroy } from '@angular/core';
import { MainHomeService } from '../../../services/main-home.service'
import { env } from '../../../config/config';
@Component({
  selector: 'app-billboard',
  templateUrl: './billboard.component.html',
  styleUrls: ['./billboard.component.scss'],
})
export class BillboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() BillboardHeight = new EventEmitter<number>();
  @ViewChild('billboardBoxDiv') billboardBoxDiv: ElementRef = null;
  billBoardWidth = 100;
  screenWidth = 0;
  paddingLength = 0;
  @Input() pageloading: boolean;
  isModalOpen = false;
  imagePath: string;
  advertImagePath = 'adverts/Billboard/';
  billBoardAdvImages: any[] = [];
  // billBoardAdvImages: any[] = [
  //   {
  //     path: this.advertImagePath + 'EHS Advanced.gif',
  //     url: 'https://europeanherniasociety.eu/news/ehs-advanced-mis-cadaveric-hernia-masterclass-2/',
  //     remove_date: '2024-11-15 23:59:59'
  //   },
  //   {
  //     path: this.advertImagePath + 'EHS Intermediate.gif',
  //     url: 'https://europeanherniasociety.eu/events/ehs-intermediate-cadaveric-hernia-masterclass-4/',
  //     remove_date: '2024-11-22 23:59:59'

  //   },
  //   {
  //     path: this.advertImagePath + 'ASGBI 2025.gif',
  //     url: 'https://events.asgbi.org.uk/asgbievents/modules/214773/html',
  //     remove_date: '2025-05-15 23:59:59'

  //   },
  //   {
  //     path: this.advertImagePath+'ESES MIR Conference.gif',
  //     url: 'https://eses2025.org/',
  //     remove_date: '2025-05-24 23:59:59'

  //   },
  //   {
  //     path: this.advertImagePath+'ESES Postgrad Camp.gif',
  //     url: 'https://www.eses.cc/eses-postgraduate-camp-in-endocrine-surgery.html',
  //     remove_date: null

  //   },
  //   {
  //     path: this.advertImagePath + 'ESES webinars.gif',
  //     url: 'https://www.eseswebinars.co.uk/',
  //     remove_date: null

  //   },
  //   {
  //     path: this.advertImagePath+'SIS-E Annual Meeting.gif',
  //     url: 'https://sis-e.org/',
  //     remove_date: '2025-05-16 23:59:59'

  //   },
  //   {
  //     path: this.advertImagePath + 'SRS Annual Meeting.gif',
  //     url: 'https://surgicalresearch.org.uk/',
  //     remove_date: '2025-01-17 23:59:59'
  //   },
  //   {
  //     path: this.advertImagePath + 'Swiss Surgical Forum.gif',
  //     url: 'https://sgc-ssc.ch/en/education/smart-surgical-forum',
  //     remove_date: null

  //   }];
  currentIndex: number = 0;
  interval: any;
  currentDateTime: string;
  noAds = false;
  isFastNetwork = true;
  isBrowser: boolean;
  filteredImages: any
  constructor(private cdr: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId: any,
    private datePipe: DatePipe, private mainService: MainHomeService) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
  @HostListener('window:resize', ['$event'])

  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = event.target.window.innerWidth;
      this.getBillboardBoxHeight();
    }

  }

  ngOnInit(): void {

    let data = {
      type: 'Billboard'
    }
    this.mainService.getBillBoardImages(data).subscribe((response: any) => {
      this.billBoardAdvImages = response.data;
      this.filteredImages = this.billBoardAdvImages.filter((image: string, index: number) => this.shouldShowImages(image, index));
      this.imagePath = env.advertisementImage;
      if (this.billBoardAdvImages.length > 0) this.pageloading = false;
      else if (this.billBoardAdvImages.length == 0) {
        this.pageloading = false
        this.noAds = true
      };

    });
    // this.filteredImages = this.billBoardAdvImages.filter((image: string, index: number) => this.shouldShowImage(image, index));

    this.currentIndex = this.billBoardAdvImages.findIndex(image => this.shouldShowImages(image));
    // this.currentDateTime = this.datePipe.transform(now, 'yyyy-MM-dd HH:mm:ss');
    if (isPlatformBrowser(this.platformId)) {
      this.checkNetworkSpeed();

      // Listen to network changes
      if ('connection' in navigator) {
        const connection = (navigator as any).connection;
        connection.addEventListener('change', this.handleNetworkChange.bind(this));
      }
      this.screenWidth = window.innerWidth;

      // window.addEventListener("DOMContentLoaded", () => {
      this.interval = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.filteredImages.length;
        // this.billBoardAdvImages = this.filteredImages.filter(image => this.shouldShowImage(image));
      }, 7000);
      // })
    }
    this.getBillboardBoxHeight();


  }


  ngAfterViewInit(): void {
    if (this.screenWidth < 1800) this.getBillboardBoxHeight();
    this.cdr.detectChanges(); // Mark for change detection
  }


  getBillboardBoxHeight(): void {
    const height = this.billboardBoxDiv?.nativeElement.offsetHeight;
    this.BillboardHeight.emit(height - 190);
  }
  openJoinModal() {
    this.isModalOpen = true;
  }

  closeJoinModal() {
    this.isModalOpen = false;
  }
  shouldShowImage(image: any, index = 0): boolean {
    if (image.remove_date == null) return true;
    if (!image.remove_date) return false;
    const removeDate = new Date(image.remove_date);
    const now = new Date();
    return now <= removeDate;
  }
  // shouldShowImages(image: any, index = 0): boolean {
  //   if (image.end_date == null) return true;
  //   if (!image.end_date) return false;
  //   const removeDate = new Date(image.end_date);
  //   const now = new Date();
  //   return now <= removeDate;
  // }
  // shouldShowImages(image: any, index = 0): boolean {
  //   if (!image.end_date) {
  //     // Show the image if end_date is null or undefined
  //     return true;
  //   }

  //   // Parse the end_date and get the current date/time
  //   const removeDate = new Date(image.end_date);
  //   const now = new Date();

  //   // Compare the current date/time with the end_date
  //   return now <= removeDate;
  // }

  shouldShowImages(image: any, index = 0): boolean {
    const now = new Date();

    // Check if start_date exists and is valid
    if (image.start_date) {
      const startDate = new Date(image.start_date);
      if (now < startDate) {
        return false; // Current date is before start_date
      }
    }

    // Check if end_date exists and is valid
    if (image.end_date) {
      const endDate = new Date(image.end_date);
      if (now > endDate) {
        return false; // Current date is after end_date
      }
    }

    // Show image if current date is between start_date and end_date
    return true;
  }

  // Method to check initial network speed
  checkNetworkSpeed(): void {
    if ('connection' in navigator) {
      const connection = (navigator as any).connection;
      // Check network speed (effectiveType can be '2g', '3g', '4g', etc.)
      if (connection.effectiveType === '2g' || connection.effectiveType === '3g') {
        this.isFastNetwork = false;
      } else {
        this.isFastNetwork = true;
      }
    }
  }

  // Handler for network change events
  handleNetworkChange(): void {
    if ('connection' in navigator) {
      const connection = (navigator as any).connection;
      this.isFastNetwork = !(connection.effectiveType === '2g' || connection.effectiveType === '3g');
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.isBrowser && 'connection' in navigator) {
      const connection = (navigator as any).connection;
      connection.removeEventListener('change', this.handleNetworkChange.bind(this));
    }
  }

}
