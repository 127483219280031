<div id="billboard" class="container-fluid p-0">
    <div #billboardBoxDiv class="billboard row">
        <div *ngIf="!pageloading && isFastNetwork ;else PageNotLoaded" class="col-9">
            <!-- <h3 class="advertisement text-center mt-4 pt-4 mb-0">Billboard
                </h3>
                <p class="text-center measure ">970 x 250</p> -->
            <div class="carousel-container">
                <div class="carousel-inner">
                    <div *ngFor="let images of filteredImages; let i = index" class="carousel-item"
                        [class.active]="currentIndex == i" [class.slide-in]="currentIndex === i">

                        <a *ngIf="currentIndex === i" class="billboard-box" [href]="images.url" target="_blank">
                            <img [src]="imagePath + images.advert_template_image" class="img-fluid sec-adv-img"
                                alt="Advertisement">
                        </a>
                    </div>
                </div>
            </div>
            <!-- <div  class="carousel-item active"
                [class.active]="currentIndex == i">

                <a target="_blank" class="billboard-box" href="{{images.url}}">
                    <img [src]="images.path" class="img-fluid sec-adv-img" loading="lazy">
                </a>
            </div> -->



            <div *ngIf="!pageloading && noAds">
                <a target="_blank" class="billboard-box box-width">
                    <h3 class="advertisement text-center mt-4 pt-4 mb-0">Billboard
                    </h3>
                    <p class="text-center measure ">970 x 250</p>
                </a>
            </div>

        </div>
        <ng-template #PageNotLoaded>
            <div *ngIf="!isFastNetwork ;else LoadingImage" class="col-9">
                <div *ngFor="let images of filteredImages; let i = index" class="carousel-item active"
                    [class.active]="i == 0">

                    <a target="_blank" class="billboard-box" href="{{images.url}}">
                        <img [src]="imagePath + images.advert_template_image" class="img-fluid sec-adv-img"
                            loading="lazy">
                    </a>
                </div>
            </div>
            <ng-template #LoadingImage>
                <div class=" skeleton box-width margin-box col-9">
                </div>
            </ng-template>

        </ng-template>
        <div class="BJS-login card col-3 p-0">
            <div class="card-body p-0">
                <div class="d-flex flex-column h-100">
                    <div class="join-background p-3">
                        <h1 class="text-center  mb-0 pt-2">JOIN</h1>
                        <h5 class="text-center mb-0 pb-2 ">BJS Academy</h5>
                    </div>
                    <div class="d-flex justify-content-center w-100 position-absolute ">
                        <span class="circle-arrow" (click)="openJoinModal()">
                            <fa-icon [icon]="['fas','right-long']"></fa-icon>
                        </span>

                    </div>
                    <div class="h-50 p-3">
                        <p class="text-left join-text ">Join BJS Academy And
                            Receive Our Latest News
                            And Updates.</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<app-modal *ngIf="isModalOpen" (close)="closeJoinModal()"></app-modal>