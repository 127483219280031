import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MainHomeService } from '../../../services/main-home.service';
import { env } from '../../../config/config';

@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrl: './leader-board.component.scss'
})
export class LeaderBoardComponent implements OnInit, OnDestroy {
  @Input() category = ''
  @Input() pageloading: boolean;
  @Input() pageType = ''
  advertImagePath = 'adverts/leaderboard/'
  // leaderBoardAdvImages: any[] = [
  //   {
  //     path: this.advertImagePath + '728 x 90 EHS Advanced MIS.png',
  //     url: 'https://europeanherniasociety.eu/news/ehs-advanced-mis-cadaveric-hernia-masterclass-2/',
  //     remove_date: '2024-11-15 23:59:59'

  //   },
  //   {
  //     path: this.advertImagePath + '728 x 90 EHS Intermediate.png',
  //     url: 'https://europeanherniasociety.eu/events/ehs-intermediate-cadaveric-hernia-masterclass-4/',
  //     remove_date: '2024-11-22 23:59:59'

  //   }
  // ];
  leaderBoardAdvImages: any[] = [];
  currentIndex: number = 0;
  interval: any;
  isFastNetwork = true;
  isBrowser: boolean;
  filteredImages: any;
  imagePath: string;
  constructor(@Inject(PLATFORM_ID) private platformId: any, private mainService: MainHomeService) {
    if (isPlatformBrowser(this.platformId)) {
      let data = {
        type: 'Leaderboard'
      }
      this.mainService.getBillBoardImages(data).subscribe((response: any) => {
        this.leaderBoardAdvImages = response.data;
        this.leaderBoardAdvImages = this.leaderBoardAdvImages.filter((image: string, index: number) => this.shouldShowImage(image, index));
        this.imagePath = env.advertisementImage;
        if (this.leaderBoardAdvImages.length > 0) this.pageloading = false;
        else if (this.leaderBoardAdvImages.length == 0) {
          this.pageloading = false
          //  this.noAds = true
        };
        this.currentIndex = this.leaderBoardAdvImages.findIndex(image => this.shouldShowImage(image));
        this.interval = setInterval(() => {
          this.currentIndex = (this.currentIndex + 1) % this.leaderBoardAdvImages.length;
          this.leaderBoardAdvImages = this.leaderBoardAdvImages.filter(image => this.shouldShowImage(image));
        }, 7000);

      });

      // this.filteredImages = this.leaderBoardAdvImages.filter((image: string, index: number) => this.shouldShowImage(image, index));
    }

  }
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkNetworkSpeed();

      // Listen to network changes
      if ('connection' in navigator) {
        const connection = (navigator as any).connection;
        connection.addEventListener('change', this.handleNetworkChange.bind(this));
      }
    }
  }
  // shouldShowImage(image: any, index = 0): boolean {
  //   if (image.end_date == null) return true;
  //   if (!image.end_date) return false;
  //   const removeDate = new Date(image.end_date);
  //   const now = new Date();
  //   return now <= removeDate;
  // }


  shouldShowImage(image: any, index = 0): boolean {
    const now = new Date();

    // Check if start_date exists and is valid
    if (image.start_date) {
      const startDate = new Date(image.start_date);
      if (now < startDate) {
        return false; // Current date is before start_date
      }
    }

    // Check if end_date exists and is valid
    if (image.end_date) {
      const endDate = new Date(image.end_date);
      if (now > endDate) {
        return false; // Current date is after end_date
      }
    }

    // Show image if current date is between start_date and end_date
    return true;
  }


  // Method to check initial network speed
  checkNetworkSpeed(): void {
    if ('connection' in navigator) {
      const connection = (navigator as any).connection;
      // Check network speed (effectiveType can be '2g', '3g', '4g', etc.)
      if (connection.effectiveType === '2g' || connection.effectiveType === '3g') {
        this.isFastNetwork = false;
      } else {
        this.isFastNetwork = true;
      }
    }
  }

  // Handler for network change events
  handleNetworkChange(): void {
    if ('connection' in navigator) {
      const connection = (navigator as any).connection;
      this.isFastNetwork = !(connection.effectiveType === '2g' || connection.effectiveType === '3g');
    }
  }
  ngOnDestroy(): void {
    if (this.isBrowser && 'connection' in navigator) {
      const connection = (navigator as any).connection;
      connection.removeEventListener('change', this.handleNetworkChange.bind(this));
    }
  }
}
