<!-- banner section -->

<app-banner *ngIf="!allArticles" [pageType]="'listpage'" (searchEvent)="handleSearch($event)"
  [pageloading]="bannerpageloading" (filterEvent)="filterEmit($event)"></app-banner>
<section id="hero" class="only-search" *ngIf="allArticles">
  <div class="container-fluid ">
    <div id="search" aria-label="Search section" class="search-section-box" #maindiv>
      <div class="row " #searchRow>
        <div class="col " *ngIf="!pageLoadingCat;else NotCat">
          <app-category (categoryValue)="handleCategory($event)" (searchEvent)="handleSearch($event)"
            (emitCheck)="filterEmit($event)" [categoryArray]="categories" [pageType]="allArticles ? 'allpage' : ''"
            [extraCategory]="extraCategory">
          </app-category>
        </div>
        <ng-template #NotCat>
          <div class="col ">
            <app-category (categoryValue)="handleCategory($event)" (searchEvent)="handleSearch($event)"
              (emitCheck)="filterEmit($event)" [categoryArray]="categories" [pageType]="allArticles ? 'allpage' : ''"
              [extraCategory]="extraCategory">
            </app-category>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</section>

<!-- listing section -->
<section class="hero1 container-fluid">
  <div class="row first-div-section">
    <div class="col-12 col-sm-7 col-md-7">
      <!-- <div class="filter" style="margin-top:17px">
        <button type="button" class="btn btn-primary btn-lg" (click)="load_filter = !load_filter;"
          [ngClass]="{'active': load_filter}" aria-label="Toggle Filter">
          <fa-icon [icon]="['fas','filter']" class="filter-icon"></fa-icon>
          Filter
        </button>
      </div> -->
      <!-- <div *ngIf="filteredArticles.length > 0" class="position-relative d-inline-block filter">
        <button type="button" class="btn btn-primary btn-lg filter-btn" (click)="load_filter = !load_filter;"
          [ngClass]="{'active': load_filter}" aria-label="Toggle Filter">
          <fa-icon [icon]="['fas','filter']" class="filter-icon"></fa-icon>
          Filter by
        </button>
        <span *ngIf="filter_total_count > 0"
          class="badge bg-secondary position-absolute top-0 start-100 translate-middle filter-count">
          {{ filter_total_count }}
        </span>
      </div> -->
    </div>
    <div *ngIf="filteredArticles.length > 0"
      class="col-12 col-sm-5 col-md-5 d-flex justify-content-md-end justify-content-sm-end justify-content-start">
      <app-pagination [currentPage]="currentPage" [totalPages]="totalPages"
        (pageChange)="onPageChange($event)"></app-pagination>
    </div>
  </div>

  <div class="row g-4 second-div-section">
    <div class="article-section p-0" [ngClass]="{
      'col-xxl-9 col-xl-10 col-lg-10 col-md-6 col-sm-6 p-0': portrait,
      'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12p-0': !portrait
    }">


      <app-article-filter *ngIf="load_filter" (filterChange)="onFilterChange($event)"
        [article]="articles"></app-article-filter>
      <div *ngIf="!pageloading; else PageArtcileListingNotLoaded">

        <div *ngIf="filteredArticles.length > 0 && category !='randomized-clinical-trials';else noArticles"
          class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4 g-4">
          <app-article-card-list *ngFor="let article of filteredArticles; let i = index" [article]="article"
            [pageType]="allArticles ? 'allpage' : 'listpage'" [index]="i" [searchValue]="searchValue"
            [contentType]="content_type" [surgicalSpecialty]="surgical_speciality" [search_filters]="search_filters"
            class="article-listing-card"></app-article-card-list>
        </div>
        <ng-template #noArticles>
          <div *ngIf="filteredArticles.length == 0 && !pageloading">
            <!-- <div class="row">
              <div class="col-12 text-center">
                <p>No articles found.</p>
                <img src="no_data_1.webp"/>
              </div>
            </div> -->

            <div style="display: flex; flex-direction: column; align-items: center; padding-bottom: 5px;">
              <img src="no_data_1.webp" alt="" class="no-data-found-img mt-4">
              <h2 class="card-title text-head-size">No articles found</h2>
            </div>
          </div>
        </ng-template>

      </div>
      <!-- to load rct articles -->
      <ng-container *ngIf="filteredArticles.length > 0 && category =='randomized-clinical-trials'">
        <app-rct-article-card *ngFor="let article of filteredArticles; let i = index"
          [pageType]="allArticles ? 'allpage' : 'listpage'" [index]="i" [searchValue]="searchValue"
          [contentType]="content_type" [surgicalSpecialty]="surgical_speciality" [search_filters]="search_filters"
          [article]="article"></app-rct-article-card>
      </ng-container>
      <!-- ends rct articles -->
      <ng-template #PageArtcileListingNotLoaded>
        <div *ngIf="category !='randomized-clinical-trials'"
          class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 g-4">
          <app-article-card-list [pageloading]="pageloading" *ngFor="let each of listLoaderarray"
            [pageloading]="pageloading" class="article-listing-card"></app-article-card-list>
        </div>
        <div *ngIf="category =='randomized-clinical-trials'">
          <app-rct-article-card *ngFor="let each of listLoaderarray" [pageloading]="pageloading"></app-rct-article-card>
        </div>
      </ng-template>


    </div>
    <!-- <div *ngIf="false"> -->
    <div class="ads-section" [ngClass]="{
    'col-xxl-3 col-xl-2 col-lg-2 col-md-6 col-sm-6': portrait,
    'col-xxl-3 col-xl-3 col-lg-4 col-md-5 col-sm-6': !portrait
  }" *ngIf="!adspageloading;else PageAdsNotLoaded">


      <app-portrait aria-label="Portrait Ad"></app-portrait>
      <!-- <app-bjs-ad aria-label="BJS Ad"></app-bjs-ad> -->
    </div>
    <ng-template #PageAdsNotLoaded>
      <div class="ads-section col-12 col-xl-3 col-lg-4 col-md-12">
        <app-portrait [pageloading]="adspageloading"></app-portrait>
        <!-- <app-bjs-ad [pageloading]="adspageloading"></app-bjs-ad> -->
      </div>
    </ng-template>
    <!-- </div> -->
  </div>

  <div *ngIf="filteredArticles.length > 0" class="row mt-5 bottom-pagination">
    <div class="last-pagination col d-flex">
      <app-pagination [currentPage]="currentPage" [totalPages]="totalPages"
        (pageChange)="onPageChange($event)"></app-pagination>
    </div>
  </div>
</section>