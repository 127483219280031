import { Component, OnInit, inject } from '@angular/core';
import { ArticleService } from '../services/article.service';
import { SharedService } from '../services/shared.service';
import { Subscription } from 'rxjs';
import { env } from '../config/config';
import { SearchContextService } from '../services/search-context-service';
import { ActivatedRoute } from '@angular/router';
import { MainHomeService } from '../services/main-home.service';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
    // CategoryarrayForHome = ['All', ' Continuing surgical education ', ' Young BJS ', ' Cutting edge blog', 'Surgical news', 'Scientific surgery'];
    // CategoryarrayForListing = [' Young BJS '];
    private readonly searchContextService = inject(SearchContextService);
    start = 0;
    length = 8;
    journalid: string = '';
    articles: any[] = [];
    subscriptions: Subscription[] = [];
    aboutPadding = 0
    bannerHeading = '';
    bannerImage = ''
    pageloading = true;
    articlePageLoading = true;
    articleListarray = new Array(8).fill(8);
    pageTopRatedLoading = true;
    billboardPageLoading = true;
    aboutPageLoader = true;
    billboard: boolean;
    constructor(private sharedService: SharedService,
        private articleService: ArticleService,
        private route: ActivatedRoute,
        private mainService: MainHomeService

    ) { }

    ngOnInit(): void {
        let data = {
            type: 'Billboard'
        }
        this.mainService.getBillBoardImages(data).subscribe((response: any) => {
            let data = response.data.filter((image: string, index: number) => this.shouldShowImages(image, index));
     
            if (data.length > 0) {
                this.billboard = true;
            }
            else {
                this.billboard = false;
            }
        });

        this.resetSearchContext();
        this.route.params.subscribe(() => {
            this.journalid = this.sharedService.getJournalId();
            const params: any = {
                start: this.start,
                length: this.length,
                category: 'featured'
            };
            // if(this.journalid){
            const articleSub = this.articleService.getArticleList(this.journalid, params).subscribe(result => {
                this.articles = result.data;
                this.articlePageLoading = false;
            });
            this.subscriptions.push(articleSub);

            // In your home component
            const subscription = this.sharedService.getAllThemsettingsData().subscribe(response => {
                if (response && response.data && response.data.banner_heading) {
                    this.bannerHeading = response.data.banner_heading;
                } else {

                }
            });

            this.subscriptions.push(subscription);
            // this.themeSettingsData = result.data;
            setTimeout(() => {
                this.pageloading = false;
                this.pageTopRatedLoading = false;
                // this.billboardPageLoading=false;
                this.aboutPageLoader = false;
            }, 1000);
            // }

        });
    }

    getBillboardBoxWidth(event: number) {
        setTimeout(() => {
            if (event) {
                this.aboutPadding = event == 0 ? 190 : event;

            }
        }, 0);
    }

    private resetSearchContext(): void {
        this.searchContextService.setSelectedSpecialities([]);
        this.searchContextService.setSelectedContentType([]);
        this.searchContextService.setSearchContent(undefined);
    }
    shouldShowImages(image: any, index = 0): boolean {
        const now = new Date();
    
        // Check if start_date exists and is valid
        if (image.start_date) {
          const startDate = new Date(image.start_date);
          if (now < startDate) {
            return false; // Current date is before start_date
          }
        }
    
        // Check if end_date exists and is valid
        if (image.end_date) {
          const endDate = new Date(image.end_date);
          if (now > endDate) {
            return false; // Current date is after end_date
          }
        }
    
        // Show image if current date is between start_date and end_date
        return true;
      }
    ngOnDestroy() {
        this.subscriptions.forEach(x => {
            if (!x.closed) {
                x.unsubscribe();
            }
        });
    }
}
