import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { env } from '../../config/config';
import { FilterService } from '../../services/filter.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-rct-article-card',
	templateUrl: './rct-article-card.component.html',
	styleUrl: './rct-article-card.component.scss'
})
export class RctArticleCardComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() article: any;
	@Input() searchValue: string;
	@Input() pageType: any;
	@Input() surgicalSpecialty: string[];
	@Input() contentType: string[];
	@Input() index: any;
	@Input() pageloading: boolean;
	@Input() search_filters: string[] = [];
	category: string;
	sharedUrl: string;
	categoryFromUrl: string = '';
	selectedFilters: any = {
		contentType: [],
		speciality: [],
		category: []
	};
	abstract: SafeHtml;
	title: SafeHtml;
	parser!: DOMParser;
	html_new_content:any;
	html_new_content_dupe: any;
	url: string;
	filters: string[] = [];
	subscription: Subscription[] = [];
	plaintitle: string;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private filterService: FilterService,
		private sanitizer: DomSanitizer,
		@Inject(PLATFORM_ID) private platformId: any,
		private cdr: ChangeDetectorRef
	) {
		if (isPlatformBrowser(this.platformId)) {
			this.parser = new DOMParser();
		}
	}
	ngOnInit() {
		this.sharedUrl = env.siteUrl;
		const routesub = this.route.params.subscribe(() => {
			this.searchValue =this.route.snapshot.params['search'] ?? ''
			if (this.route.snapshot.params['category']) {
				this.category = this.route.snapshot.params['category'];
			}
		});
		this.subscription.push(routesub);

		const paramsub = this.route.queryParams.subscribe(params => {
			this.categoryFromUrl = params['category'] || ''; // Extract category from URL
		});
		this.subscription.push(paramsub);

		// this.abstract = this.sanitizer.bypassSecurityTrustHtml(this.article?.abstract);
		// console.log(this.abstract);

		this.title = this.highlightSearchValue(this.article?.article_title, this.searchValue, 'title');
		this.plaintitle = this.toRemoveHtml(this.article?.article_title)

		// this.title = this.sanitizer.bypassSecurityTrustHtml(this.article?.article_title);

		// this.url = '/bjs-academy/'+this.article?.category_heading.id+'/'+this.article?.article_link;

	}

	ngAfterViewInit() {
		if (this.article?.abstract) {
			this.restructureAbstarct(this.article?.abstract);
		}
		else {
			this.restructureAbstarct(this.article?.html_content);
		}

	}

	restructureAbstarct(abstract: string) {
		if (isPlatformBrowser(this.platformId) && abstract) {

			const html = this.parser?.parseFromString(abstract, 'text/html');    

			// to hide front tag
			const content = html?.querySelector("[class='front']");
			content?.classList.add('hide');

			// Create a temporary HTML element
			// const html: any = document.createElement('div');

			// Assign the abstract string as the innerHTML
			// html.innerHTML = abstract;

			//restructuring images
			const fig_tags = html.querySelectorAll("fig");

			Array.from(fig_tags).forEach((eachImage: any) => {

				//for loading vimeo widgets bjs-academyUrl
				const bjs_url = eachImage.querySelectorAll('[ext-link-type="bjs-academyUrl"]');
				if (bjs_url.length > 0) {
					bjs_url.forEach((eachUrl: any) => {
						const vimeoLink = eachUrl?.getAttribute('xlink:href');
						if (vimeoLink) {
							const vimeo_link_array = vimeoLink.split('/');
							const vimeo_id = vimeo_link_array['4'];

							if (vimeo_id != undefined) {
								const embeded_vimeo_html = '<iframe class="bjsclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
								const divele = document.createElement('div');
								divele?.classList.add('vimeo_widget');
								divele.innerHTML = embeded_vimeo_html;
								// eachVimeo.innerHTML = embeded_vimeo_html;
								eachUrl.appendChild(divele);
							}
							eachImage.classList.remove('fig');
							eachImage.classList.add('link');
						}

					})
				}

				//for loading vimeo widgets Url
				const url = eachImage.querySelectorAll('[ext-link-type="Url"]');
				if (url.length > 0) {
					url.forEach((eachUrl: any) => {
						const vimeoLink = eachUrl?.getAttribute('xlink:href');
						if (vimeoLink) {
							const vimeo_link_array = vimeoLink.split('/');
							const vimeo_id = vimeo_link_array['4'];

							if (vimeo_id != undefined) {
								const embeded_vimeo_html = '<iframe class="bjsclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
								const divele = document.createElement('div');
								divele?.classList.add('vimeo_widget');
								divele.innerHTML = embeded_vimeo_html;
								// eachVimeo.innerHTML = embeded_vimeo_html;
								eachUrl.appendChild(divele);
							}
							eachImage.classList.remove('fig');
							eachImage.classList.add('link');
						}

					})
				}

				//for loading vimeo widgets
				const vimeo = eachImage.querySelectorAll('[ext-link-type="vimeoUrl"]');
				if (vimeo.length > 0) {
					vimeo.forEach((eachVimeo: any) => {
						const vimeoLink = eachVimeo?.getAttribute('xlink:href');
						if (vimeoLink) {
							const vimeo_link_array = vimeoLink.split('/');
							const vimeo_id = vimeo_link_array['4'];

							if (vimeo_id != undefined) {
								const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
								const divele = document.createElement('div');
								divele?.classList.add('vimeo_widget');
								divele.innerHTML = embeded_vimeo_html;
								// eachVimeo.innerHTML = embeded_vimeo_html;
								eachVimeo.appendChild(divele);
							}
							eachImage.classList.remove('fig');
							eachImage.classList.add('link');
						}
					});
				}

				//for spotify
				const spotify = eachImage.querySelectorAll('[ext-link-type="anchor-fm-incUrl"]');

				if (spotify.length > 0) {
					spotify.forEach((eachSpotify: any) => {
						const spotifyLink = eachSpotify?.getAttribute('xlink:href');
						if (spotifyLink) {
							const spotify_link_array = spotifyLink.split('/');
							const spotify_id = spotify_link_array['4'];
							if (spotify_id !== undefined) {
								const embeded_spotify_html = `
                                    <iframe 
                                        class="wp-embedded-content rctspotifyclass" 
                                        security="restricted" 
                                        title="Spotify Player" 
                                        src="${spotifyLink}" 
                                        data-secret="2SKUdoXe0b" 
                                        height="205px" 
                                        width="400px" 
                                        frameborder="0" 
                                        scrolling="no" 
                                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                                        allowtransparency="true"
                                        style="border:none; overflow:hidden;">
                                    </iframe>`;

								const divele = document.createElement('div');
								divele.classList.add('spotify_widget');
								divele.innerHTML = embeded_spotify_html;
								eachSpotify.appendChild(divele);
							}

							eachImage.classList.remove('fig');
							eachImage.classList.add('link');
							const altText: any = eachImage.querySelector('alt-text');
							altText.style.display = 'none'; // Hides the image
						}

					})
				}

				//for loading twitter links
				const twitter = eachImage.querySelectorAll('[ext-link-type="twitterUrl"]');
				if (twitter.length > 0) {
					twitter.forEach((eachTwitter: any) => {
						let twitterLink: any = eachTwitter?.getAttribute('xlink:href');
						if (twitterLink) {
							const twitter_link_array = twitterLink.split('/');
							const length = twitter_link_array?.length;
							const twitter_id = twitter_link_array[length - 1];
							if (twitter_id !== undefined) {
								// Create a blockquote for embedding the tweet
								const embeded_twitter_html = `
                                <blockquote class="twitter-tweet">
                                    <a href="${twitterLink}"></a>
                                </blockquote>
                            `;
								const divele = document.createElement('div');
								divele.innerHTML = embeded_twitter_html;
								eachImage.appendChild(divele);

								// Load Twitter's embed script
								if (!document.querySelector('script#twitter-wjs')) {
									const script = document.createElement('script');
									script.id = 'twitter-wjs';
									script.src = "https://platform.twitter.com/widgets.js";
									script.onload = () => {
										(window as any).twttr.widgets.load();
									};
									document.body.appendChild(script);
								} else {
									// If script already exists, reload widgets after a short delay
									setTimeout(() => {
										if ((window as any).twttr && (window as any).twttr.widgets) {
											(window as any).twttr.widgets.load();
										}
									}, 500);
								}

								const disp_quote = eachImage.querySelectorAll('[class="disp-quote"]');
								disp_quote.forEach((each_quote: any) => {
									each_quote.classList.add('hide');
								})
							}
							// eachImage.classList.remove('fig');
						}
					});
				}

				//for loading youtube videos
				const youtubeLinks = eachImage.querySelectorAll('[ext-link-type="youtubeUrl"]');
				youtubeLinks.forEach((eachYouTube: any) => {
					const vimeoLink = eachYouTube?.getAttribute('xlink:href');
					if (vimeoLink) {
						const vimeo_link_array = vimeoLink.split('/');
						const vimeo_id = vimeo_link_array['4'];

						if (vimeo_id != undefined) {
							const embeded_vimeo_html = '<iframe class="iframeclass" loading="lazy" title="About Us Template" src="' + vimeoLink + '" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" data-ratio="0.562" data-width="750" data-height="450"></iframe>';
							const divele = document.createElement('div');
							divele?.classList.add('vimeo_widget');
							divele.innerHTML = embeded_vimeo_html;
							// eachVimeo.innerHTML = embeded_vimeo_html;
							eachYouTube.appendChild(divele);
						}
						eachImage.classList.remove('fig');
						eachImage.classList.add('link');
					}
				});

			});

			//recreating html............
			const serializer = new XMLSerializer();
			this.html_new_content = serializer.serializeToString(html);
			this.html_new_content_dupe = this.sanitizer.bypassSecurityTrustHtml(this.html_new_content);
			// return this.html_new_content_dupe;
			// setTimeout(() => {
			// this.html_new_content_dupe = `<div xmlns="http://www.w3.org/1999/xhtml"><sec><title></title><p><italic>JAMA Surg</italic> 2024; <bold>159</bold>: 606-614. </p><fig fig-type="anchor-fm-inc"></fig></sec></div>`;

			this.cdr.detectChanges();
			//   }, 1000);

		}

	}

	isStringArray(authors: any[]): boolean {
		return typeof authors[0] === 'string';
	}

	isObjectArray(authors: any[]): boolean {
		return typeof authors[0] === 'object';
	}

	returnCategory(category: string) {
		if (category == 'social-media' || category == 'randomized-clinical-trials' || category == 'surgical-science' || category == 'surgery-for-all') {
			return true;
		}
		else {
			return false;
		}
	}

	navigateToArticle(article_link: any, category_heading: any) {
		if (category_heading) {
			this.router.navigate(['/bjs-academy', category_heading, article_link]).then(navigated => {

				if (!navigated) {
					// Handle failed navigation
				}
			});
		}
	}

	categoryClick(value: any, title: any) {
		this.toggleFilter(this.selectedFilters.category, value);
		this.updateQueryParams(value);
	}
	updateQueryParams(scientific_surgery: string = '') {
		const currentQueryParams = { ...this.route.snapshot.queryParams };
		if (this.selectedFilters.contentType.length) {
			currentQueryParams['contentType'] = this.selectedFilters.contentType.join(',');
		} else {
			delete currentQueryParams['contentType'];
		}
		if (this.selectedFilters.speciality.length) {
			currentQueryParams['speciality'] = this.selectedFilters.speciality.join(',');
		} else {
			delete currentQueryParams['speciality'];
		}
		if (this.selectedFilters.category.length) {
			currentQueryParams['category'] = this.selectedFilters.category.join(',');
		} else {
			delete currentQueryParams['category'];
		}

		if (this.searchValue) {
			currentQueryParams['search'] = this.searchValue;
		} else {
			delete currentQueryParams['search'];
		}

		let path: string[];
		if (this.pageType === undefined || this.pageType === "allpage") {
			path = ['/bjs-academy', 'all-articles', 'page', '1'];
		} else if (this.pageType === "listpage" && ['social-media', 'randomized-clinical-trials', 'surgical-science', 'surgery-for-all'].includes(scientific_surgery)) {
			path = ['/bjs-academy', this.category, scientific_surgery, 'page', '1'];
		}
		else if (this.pageType === "listpage") {
			path = ['/bjs-academy', this.category, 'page', '1'];
		}
		else {
			path = ['/bjs-academy', 'all-articles', 'page', '1'];
		}

		this.router.navigate(path, {
			queryParams: currentQueryParams,
			queryParamsHandling: 'merge'
		}).then(() => {
		}).catch(err => {
		});
	}

	filterClicked(value: string, filterType: string, scientific_surgery: string = '') {
		if (filterType === 'type') {
			this.toggleFilter(this.selectedFilters.contentType, value);
			this.filterService.setContentType(this.selectedFilters.contentType);
		} else if (filterType === 'speciality') {
			this.toggleFilter(this.selectedFilters.speciality, value);
			this.filterService.setSpeciality(this.selectedFilters.speciality);
		} else if (filterType === 'category') {
			this.toggleFilter(this.selectedFilters.category, value);
			this.filterService.setCategory(this.selectedFilters.category);
		}

		this.updateQueryParams(scientific_surgery);
	}

	toggleFilter(filterArray: string[], value: string) {

		const index = filterArray.indexOf(value);
		if (index === -1) {
			filterArray.push(value);  // Add the value if it doesn't exist
		} else {
			filterArray.splice(index, 1);  // Remove the value if it exists
		}

	}

	highlightReference(tag: string): SafeHtml {

		// Check if surgicalSpecialty is defined and is an array
		if (!this.surgicalSpecialty || !Array.isArray(this.surgicalSpecialty)) {
			return this.sanitizer.bypassSecurityTrustHtml(tag);
		}

		// Perform highlighting for each name in surgicalSpecialty
		for (const name of this.surgicalSpecialty) {
			const regex = new RegExp('\\b' + name + '\\b', 'gi');
			tag = tag.replace(regex, `<span style="background-color: yellow;
			border-radius: 5px;
			color:black;
			padding: 0px 5px;
			border-style: solid;
			border-color: rgb(85, 85, 221);
			border-width: 1px;
			display: inline-block;">$&</span>`);
		}

		return this.sanitizer.bypassSecurityTrustHtml(tag);
	}

	highlightType(type: string): SafeHtml {
		if (!this.contentType || !Array.isArray(this.contentType)) {
			return this.sanitizer.bypassSecurityTrustHtml(type);
		}

		// const isHighlighted = Array.isArray(this.contentType) && this.contentType.includes(type);
		// const className = isHighlighted ? 'highlight' : '';
		// const html = `<span class="${className}">${type}</span>`;

		// Perform highlighting for each name in surgicalSpecialty
		for (const name of this.contentType) {
			const regex = new RegExp('\\b' + name + '\\b', 'gi');
			type = type.replace(regex, `<span style="background-color: yellow;
			  border-radius: 5px;
			  color:black;
			  padding: 0px 5px;
			  border-style: solid;
			  border-color: rgb(85, 85, 221);
			  border-width: 1px;
			  display: inline-block;">$&</span>`);
		}
		return this.sanitizer.bypassSecurityTrustHtml(type);
	}

	highlightReferenceHeading(tag: string): SafeHtml {
		// Ensure that the article and category_tags are defined
		if (!this.article || !Array.isArray(this.article.category_tags) || !tag) {
			return this.sanitizer.bypassSecurityTrustHtml(tag);
		}
		if (this.categoryFromUrl) {
			this.article.category_tags.forEach((categoryTag: any) => {
				const regex = new RegExp('\\b' + categoryTag + '\\b', 'gi');
				tag = tag.replace(regex, `<span style="background-color: yellow;
					border-radius: 5px;
					color: black;
					padding: 0px 5px;
					border-style: solid;
					border-color: rgb(85, 85, 221);
					border-width: 1px;
					display: inline-block;">$&</span>`);
			});
		}

		// Bypass Angular's security to safely inject the HTML
		return this.sanitizer.bypassSecurityTrustHtml(tag);
	}

	highlightSearchValue(title: string, searchValue: string, type: string): SafeHtml {
		if (searchValue && (this.filters.length === 0 || this.filters.includes(type))) {
			const normalizedSearchValue = searchValue.toLowerCase();
			const regex = new RegExp(normalizedSearchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'); // Escape special characters
			// Replace matches with a highlighted span
			const highlightedTitle = title.replace(regex, (match) => `<span style="background-color: yellow">${match}</span>`);
			return this.sanitizer.bypassSecurityTrustHtml(highlightedTitle); // Use DomSanitizer to prevent XSS
		}
		return this.sanitizer.bypassSecurityTrustHtml(title);
	}
	

	toRemoveHtml(description: string): string {
		let decodedText = '';
		if (description) {
			decodedText = description.replace(/<[^>]*>/g, '').trim(); // Removes all HTML tags and trims whitespace
		}
		return decodedText;
	}
	ngOnDestroy() {
		this.subscription.forEach(x => {
			if (!x.closed) {
				x.unsubscribe();
			}
		});
	}
}
