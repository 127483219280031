import { Component, EventEmitter, Input, OnChanges, SimpleChanges, Output, PLATFORM_ID, Inject, HostListener, OnInit, inject, ElementRef, Renderer2, AfterViewInit, ViewChild, AfterViewChecked } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleService } from '../../services/article.service';
import { SearchContextService } from '../../services/search-context-service';
import { FilterServiceStateService } from '../../filter-service-state.service';
import { FilterService } from '../../services/filter.service';
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {
  @Input() categoryArray: string[] = [];
  @Input() pageType = '';
  // @Input() articles: any;

  @Input() contentType: any;
  @Input() surgicalSpecialty: any;
  @Input() extraCategory: string[] = [];

  @ViewChild('filterCard') filterCard!: ElementRef | undefined;
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu!: ElementRef;
  @ViewChild('buttonEl', { static: false }) buttonEl!: ElementRef;
  selectedCat: string = 'All';
  screenWidth = 0;
  category: string = '';
  categoryContent: any[] = [];
  homepage: string = 'homepage';
  listpage: string = 'listpage';
  allpage: string = 'allpage';
  @Output() searchEvent = new EventEmitter<string>();

  @Output() categoryValue = new EventEmitter<string>();
  @Output() emitCheck = new EventEmitter<string[]>();
  @ViewChild('childRef') childInstance!: SearchComponent;
  @ViewChild('searchFilterElement') childFilterElement!: SearchComponent;

  categoryTitles: any[] = [];
  selectedCatheading = 'All';
  pageloading = true;
  tabarray = new Array(8).fill(0);
  // extraCategoryTitles: string[] = ['Category 4', 'Category 5', 'Category 6', 'Category 7']; // Extra categories
  allCategories: string[] = []; // Combine categories
  dropdownOpen: boolean = false;
  extraCategoryTitles: string[] = [];
  selectedDropdownCategory: string = 'Scientific surgery';
  showActiveTab = 'All';
  load_filter = false;
  filter_total_count: number;
  private readonly searchContextService = inject(SearchContextService);
  uniqueSurgicalSpecialities: string[] = [];
  uniqueContentTypes: string[] = [];
  surgicalView = true;
  contentTypeView = true;
  sub_category: any
  load_moremenu = false;
  isDropdownOpen = false;
  isDropdownInitialized = false;
  @ViewChild('tabContainer') tabContainer: ElementRef;
  AllcategoryArray:string[]=[];
  filteredCategoryArray: string[];
  homepageValue=false;
  hideLeaderboard = false;
 
  private scrollOffset = 600;
  isScrolled=false;

  uncheck: { [key: string]: boolean } = { title:false,author: false, doi: false };

  search_filters: string[] = [];

  //categoryTitles: string[] = ['All', ...this.categoryArray.map((subMenu: any) => subMenu.menu_name)];

  constructor(@Inject(PLATFORM_ID) private platformId: any, private route: ActivatedRoute, private articleService: ArticleService,
    private router: Router, private filterStateService: FilterServiceStateService, private renderer: Renderer2,
    private filterService:FilterService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
    }
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  
    
    this.isScrolled = offset > this.scrollOffset;
    this.hideLeaderboard = offset > this.scrollOffset;
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (this.filterCard && !this.filterCard.nativeElement.contains(targetElement) && !targetElement.closest('.filter-btn')) {
      this.load_filter = false; // Close the filter if clicked outside
      // this.clearFilters();

    }
  }

  ngOnInit(): void {

    if(this.pageType === 'homepage'){
      this.homepageValue=true;
      this.searchContextService.setSelectedSpecialities([]);
      this.searchContextService.setSelectedContentType([]);
      this.updateCategoryTitles();
    }
    this.updateCategoryTitles();
    this.route.queryParams.subscribe(params => {
      const filters = params['filters']?.split(',');
      filters?.forEach((filter:any) => {
        if (filter in this.uncheck) {
          this.uncheck[filter] = true;
        }
      });      
    });
    this.route.params.subscribe(params => {
    
      if (params['subcategory']) {
        this.category = params['subcategory'];
      } else {
        this.category = params['category'];
      }
      if (this.AllcategoryArray && this.category) {
        this.filteredCategoryArray = this.AllcategoryArray.filter((category: any) => category.identifier !== this.category);
      }

      if(params['filters']){
        
      }

    });
    if (this.pageType === 'homepage') {
      this.selectedCat = 'All';

    } if (this.pageType === 'allpage') {
      this.selectedCat = 'All';
      this.category = "all-page";
      if (this.categoryArray) {
        this.categoryTitles = this.categoryArray;

      }
    }
    else {
      this.getCategoryContent(this.category);
      this.selectedCat = this.category;
    }
    this.sub_category = this.route.snapshot.params['subcategory'];
    this.articleService.getFilterData().subscribe(
      (result: any) => {
        // this.apiData = result;
        this.uniqueSurgicalSpecialities = result['surgical_speciality']
        this.uniqueContentTypes = this.sub_category ? [] : result['content_type']
        // this.pageLoading = false; 
      },
      error => {
        //console.error('An error occurred:', error);
      }
    );
    this.clearFilters();
    if(this.categoryArray)this.pageloading=false;
   
    
    
  }
  ngAfterViewInit() {
    if (this.dropdownOpen) {
      this.positionDropdown(); // Position dropdown only if it's open
    }
  }
  ngAfterViewChecked() {
    // Ensure dropdown is initialized after view is checked
    if (!this.isDropdownInitialized && isPlatformBrowser(this.platformId)) {
      this.positionDropdown();
      this.isDropdownInitialized = true;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryArray'] && !changes['categoryArray'].isFirstChange()) {
      if (this.pageType == 'allpage' && this.categoryArray.length > 0) {
        this.pageloading = false;
      }
  
      this.updateCategoryTitles();
    }
  
    if (this.extraCategory) {
      this.AllcategoryArray = [
        ...(this.categoryArray || []), 
        ...(this.extraCategory || [])
      ];
    }
  
    if (this.AllcategoryArray && this.childInstance) {
      this.childInstance.getDropdownData(this.AllcategoryArray);
    }
    if (this.AllcategoryArray && this.category) {
      this.filteredCategoryArray = this.AllcategoryArray.filter((category: any) => category.identifier !== this.category);
    }
    if(this.filteredCategoryArray)
    {
      this.childFilterElement.getDropdownData(this.filteredCategoryArray);
    }
  }
  
//   ngOnChanges(changes: SimpleChanges): void {
//     if (changes['categoryArray'] && !changes['categoryArray'].isFirstChange()) {
//       // Called when categoryArray input changes
//       if (this.pageType == 'allpage' && this.categoryArray.length > 0) {
//         this.pageloading = false
      
//       }
      
//       this.updateCategoryTitles();
//       //this.resetSearchContext();
//     }
    
//      if (this.extraCategory) {
//       // Ensure both categoryArray and extraCategory are arrays
//       this.AllcategoryArray = [
//         ...(this.categoryArray || []), 
//         ...(this.extraCategory || [])
//       ];
     
     
//     }
//     if(this.AllcategoryArray)
// {
// this.childInstance.getDropdownData(this.AllcategoryArray);

// }
//   }

  updateCategoryTitles(): void {
    if (this.categoryArray) {
      this.categoryTitles = ['All', ...this.categoryArray.map((subMenu: any) => subMenu.menu_name)];
      this.extraCategoryTitles = [...this.extraCategory.map((subMenu: any) => subMenu.menu_name)]
      this.allCategories = [...this.categoryTitles, ...this.extraCategoryTitles];
      // this.categoryArray.push()
     
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (isPlatformBrowser(this.platformId)) this.screenWidth = window.innerWidth;
    this.positionDropdown();
  }

  // selectedCategory(heading: string) {
  //   this.selectedCatheading = heading;
  //   if (heading === 'All') {
  //     // Handle the "All" case
  //     this.selectedCatheading = heading;
  //     this.selectedCat = 'All'; // Clear the selected category or set to a default value
  //             this.categoryValue.emit(this.selectedCat)

  //    // this.searchEvent.emit(this.selectedCat);
  //   } else {
  //     //console.log(this.categoryContent);

  //     // Find the object in the array that matches the given heading
  //     const selectedCategoryObj: any = this.categoryContent.find((category: any) => category.heading === heading);

  //     if (selectedCategoryObj) {
  //       // Replace underscores with hyphens in the menu value
  //       const formattedCategory = selectedCategoryObj.menu.replace(/_/g, '-');
  //      // this.selectedCatheading = selectedCategoryObj.menu;
  //       // Set the selected category and pass it to the search component
  //       this.selectedCat = formattedCategory;
  //       this.category = this.selectedCat;
  //       this.categoryValue.emit(this.selectedCat)
  //       this.selectedCatheading=heading;
  //     //  console.log(searchValue); // For debugging
  //     } else {
  //       console.error('Category object not found:', selectedCategoryObj);
  //     }
  //   }
  // }

  selectedCategory(heading: string) {

    if (this.selectedCatheading !== heading) {
      this.selectedCatheading = heading;
      if (heading === 'All') {
        this.selectedCat = 'All';
        this.categoryValue.emit(this.selectedCat);

      } else {
        this.selectedCat = heading.toLocaleLowerCase().replace(/ /g, '-')
        // const data = {
        //   category: heading
        // }
        // this.articleService.getCategoryData(data).subscribe((response: any) => {
        //   this.selectedCat = response.data[0].menu;

        //   this.categoryValue.emit(this.selectedCat);
        //   // this.resetSearchContext();

        // });
        const selectedCategoryObj: any = this.categoryContent.find((category: any) => category.heading === heading);
        if (selectedCategoryObj) {
          const formattedCategory = selectedCategoryObj.menu.replace(/_/g, '-');
          this.selectedCat = formattedCategory;
          this.category = this.selectedCat;
          this.categoryValue.emit(this.selectedCat);
        } else {
        }
      }
      this.showActiveTab = heading;
    }
  }

  onSearchEvent(searchValue: string) {
    this.searchContextService.setSearchContent(searchValue);

    this.searchEvent.emit(searchValue);
    this.emitCheck.emit(this.search_filters);
  }

  getCategoryContent(category: string) {
    const data = {
      category: category
    }
    this.articleService.getCategoryContent(data).subscribe((response: any) => {
      this.categoryContent = response.data;
      this.pageloading = false;

    });

  }
  trackByCategory(index: number, category: any): string {
    return category; // or return category.identifier if you have a unique identifier
  }
  private resetSearchContext(): void {
    this.searchContextService.setSelectedSpecialities([]);
    this.searchContextService.setSelectedContentType([]);
    this.searchContextService.setSearchContent(undefined);
  }
  closeFilter() {
    this.surgicalView = false;
    this.contentTypeView = false;
  }
  clearFilters() {
    this.searchContextService.setSelectedSpecialities([]);
    this.searchContextService.setSelectedContentType([]);
    this.searchContextService.clearFilters();
    this.filterService.setCategory([]);
    if (this.searchContextService.selectedSpecialities().length=== 0 && this.searchContextService.selectedSpecialities().length === 0) {
      this.filter_total_count =0;
    }
    // this.filterChange.emit({ surgical_speciality: [], content_type: [] });
    // this.router.navigate([], {
    //     relativeTo: this.route,
    //     queryParams: {},
    //     replaceUrl: true,
    // });
  }

  isSpecialitySelected(speciality: string): boolean {
    return this.searchContextService.selectedSpecialities().includes(speciality);
  }

  isContentTypeSelected(type: string): boolean {
    return this.searchContextService.selectedContentType().includes(type);
  }
  onSpecialityChange(speciality: string) {
    let selectedSpecialities: string[] = this.searchContextService.selectedSpecialities();

    if (selectedSpecialities.includes(speciality)) {
      selectedSpecialities = selectedSpecialities.filter(s => s !== speciality);
    } else {
      selectedSpecialities.push(speciality);
    }

    this.searchContextService.setSelectedSpecialities(selectedSpecialities);
    this.filterStateService.setFilterState({
      surgical_speciality: this.searchContextService.selectedSpecialities(),
      content_type: this.searchContextService.selectedContentType()
    });
    // Update the query parameters
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: {
    //     speciality: selectedSpecialities.join(','),
    //     contentType: this.searchContextService.selectedContentType().join(',')
    //   },
    //   queryParamsHandling: 'merge' // To preserve existing query params
    // });

    // this.emitFilterChange();
    this.filter_total_count = this.searchContextService.selectedSpecialities().length + this.searchContextService.selectedContentType().length;
  }

  onContentTypeChange(type: string) {
    let selectedContentTypes = this.searchContextService.selectedContentType();

    if (selectedContentTypes.includes(type)) {
      selectedContentTypes = selectedContentTypes.filter(t => t !== type);
    } else {
      selectedContentTypes.push(type);
    }

    this.searchContextService.setSelectedContentType(selectedContentTypes);
    this.filterStateService.setFilterState({
      surgical_speciality: this.searchContextService.selectedSpecialities(),
      content_type: this.searchContextService.selectedContentType()
    });
    this.filter_total_count = this.searchContextService.selectedSpecialities().length + this.searchContextService.selectedContentType().length;
    // Update the query parameters
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: {
    // speciality: this.searchContextService.selectedSpecialities().join(','),
    // contentType: selectedContentTypes.join(',')
    //   },
    //   queryParamsHandling: 'merge' // To preserve existing query params
    // });


    // this.emitFilterChange();

  }
  scrollTabs(direction: 'left' | 'right') {
    const scrollAmount = 50;  // Adjust scroll amount as needed
    if (direction === 'left') {
      this.tabContainer.nativeElement.scrollLeft -= scrollAmount;
    } else {
      this.tabContainer.nativeElement.scrollLeft += scrollAmount;
    }
  }

  positionDropdown() {

    const buttonRect = this.buttonEl?.nativeElement.getBoundingClientRect();
    const dropdown = this.dropdownMenu?.nativeElement;
    if (this.screenWidth < 1734 && dropdown) this.renderer.setStyle(dropdown, 'left', `${buttonRect.left - 75}px`);

  }

  checkBoxChange(event: Event, field: string) {

    const ind = this.search_filters.findIndex(x => x === (event.target as HTMLInputElement).value);
    this.uncheck[field] = (event.target as HTMLInputElement).checked;
    // this.clearButton = true;
    if (ind == -1 && (event.target as HTMLInputElement).checked) {
       
        this.search_filters.push((event.target as HTMLInputElement).value);
    }
    if ((event.target as HTMLInputElement).checked === false && ind !== -1) {
        this.search_filters.splice(ind, 1);
        // if (Object.values(this.uncheck).every(value => !value)) {
        //     this.clearButton = false;
        // }
    }
    this.searchContextService.setSearchFilter(this.search_filters);
    this.emitCheck.emit(this.search_filters);
}
clearEmit(search_filters:string[]){
  this.searchContextService.setSearchFilter(search_filters);
  this.uncheck = {titile:false, author: false, doi: false };
}
shouldShowImages(image: any, index = 0): boolean {
  const now = new Date();

  // Check if start_date exists and is valid
  if (image.start_date) {
    const startDate = new Date(image.start_date);
    if (now < startDate) {
      return false; // Current date is before start_date
    }
  }

  // Check if end_date exists and is valid
  if (image.end_date) {
    const endDate = new Date(image.end_date);
    if (now > endDate) {
      return false; // Current date is after end_date
    }
  }

  // Show image if current date is between start_date and end_date
  return true;
}

}
